import {createResourceReducer} from 'src/shared/utils/resource';
import {
  READ_ACCOUNT_COMPLETE,
  READ_ACCOUNT_ERROR,
  READ_ACCOUNT_START,
  READ_ACCOUNT_SUCCESS,
} from './actions';

export const account = createResourceReducer({
  start: READ_ACCOUNT_START,
  success: READ_ACCOUNT_SUCCESS,
  error: READ_ACCOUNT_ERROR,
  complete: READ_ACCOUNT_COMPLETE,
});
