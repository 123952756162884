import {connect} from 'react-redux';
import {DeviceInfoPage} from './DeviceInfoPage';
import {currentView} from 'src/shared/currentView/selectors';
import {voiceStatus} from 'src/shared/user/selectors';
import {signIn} from 'src/shared/config/urls';
import {deviceSearch} from '../store/actions/deviceSearch';
import {setBrand} from '../store/actions/setBrand';
import {setBuiltInWifi} from '../store/actions/setBuiltInWifi';
import {setVoiceEnabled} from '../store/actions/setVoiceEnabled';
import {setShowUnsupportedDevices} from '../store/actions/setShowUnsupportedDevices';
import {setDownloadSpeed} from '../store/actions/setDownloadSpeed';
import {setDocsisChannels} from '../store/actions/setDocsisChannels';
import {setDocsisVersion} from '../store/actions/setDocsisVersion';
import {setClearAllFilter} from '../store/actions/setClearAll';
import {setSortKey} from '../store/actions/setSortKey';
import {viewDetails} from '../store/actions/viewDetails';
import {setDisabledSpeedTiers} from '../store/actions/setDisabledSpeedTiers';
import {redirect} from 'src/shared/redirect/actions';
import {deviceInfo} from 'src/shared/pagePaths';
import {
  compareDevices,
  selectDevicesForComparison,
  clearAllDevicesForComparison,
} from '../store/actions/compareDevices';
import {
  clearAddressSuggestions,
  findDevices,
  getAddressSpeed,
  loadZipcodeSpeedTiers,
  loadAddressSuggestions,
  loadDeviceResults,
  resetTempLocationType,
  setInvalidModalClose,
  setIsEPONAddress,
  setTempLocationType,
  setTempLocation,
} from 'src/pages/deviceInfo/store/actions/findDevices';
import {setYourDevice} from '../store/actions/setYourDevice';
import {
  account,
  accountSpeedTier,
  addressSuggestions,
  addressSpeedTiers,
  comparedDevices,
  deviceAutofill,
  deviceDetails,
  deviceFilters,
  deviceResults,
  devicesForComparison,
  disabledSpeedTiers,
  downloadSpeedTiers,
  filtersDropdown,
  isLoadingDevices,
  isLoadingSpeedTiers,
  isEPONAddress,
  sortKey,
  keyWord,
  location,
  locationErrorMessage,
  yourDevice,
  yourEquipment,
  zipcodeAutofill,
  zipcodeSpeedTiers,
} from 'src/pages/deviceInfo/store/selectors/deviceInfo';

export const mapStateToProps = state => ({
  account: account(state),
  accountSpeedTier: accountSpeedTier(state),
  addressSuggestions: addressSuggestions(state),
  addressSpeedTiers: addressSpeedTiers(state),
  comparedDevices: comparedDevices(state),
  currentView: currentView(state),
  deviceAutofill: deviceAutofill(state),
  deviceDetails: deviceDetails(state),
  deviceFilters: deviceFilters(state),
  deviceResults: deviceResults(state),
  devicesForComparison: devicesForComparison(state),
  disabledSpeedTiers: disabledSpeedTiers(state),
  downloadSpeedTiers: downloadSpeedTiers(state),
  filtersDropdown: filtersDropdown(state),
  isLoadingDevices: isLoadingDevices(state),
  isLoadingSpeedTiers: isLoadingSpeedTiers(state),
  isEPONAddress: isEPONAddress(state),
  keyWord: keyWord(state),
  location: location(state),
  locationErrorMessage: locationErrorMessage(state),
  signIn: signIn(state),
  sortKey: sortKey(state),
  voiceStatus: voiceStatus(state),
  yourDevice: yourDevice(state),
  yourEquipment: yourEquipment(state),
  zipcodeAutofill: zipcodeAutofill(state),
  zipcodeSpeedTiers: zipcodeSpeedTiers(state),
});

export const mapDispatchToProps = dispatch => ({
  onAddressChange: address => dispatch(getAddressSpeed(address)),
  onBrandChange: brand => {
    dispatch(setBrand(brand));
    dispatch(loadDeviceResults());
  },
  onBuiltInWifiChange: boolean => {
    dispatch(setBuiltInWifi(boolean));
    dispatch(loadDeviceResults());
  },
  onClearAddressSuggestions: () => dispatch(clearAddressSuggestions()),
  onClearAll: () => {
    dispatch(setClearAllFilter());
    dispatch(loadDeviceResults());
  },
  onClearAllDevice: () => dispatch(clearAllDevicesForComparison()),
  onCompare: ids => dispatch(compareDevices(ids)),
  onDetails: devicesId => dispatch(viewDetails(devicesId)),
  onDisabledSpeedTiers: bool => dispatch(setDisabledSpeedTiers(bool)),
  onDocsisChannelsChange: channel => {
    dispatch(setDocsisChannels(channel));
    dispatch(loadDeviceResults());
  },
  onDocsisVersionChange: version => {
    dispatch(setDocsisVersion(version));
    dispatch(loadDeviceResults());
  },
  onDownloadSpeedChange: speed => {
    dispatch(setDownloadSpeed(speed));
    dispatch(loadDeviceResults());
  },
  onFindDevices: speed => dispatch(findDevices(speed)),
  onLoadAddressSuggestions: address => dispatch(loadAddressSuggestions(address)),
  onTempLocationTypeChange: locationType => dispatch(setTempLocationType(locationType)),
  onTempLocationTypeReset: () => dispatch(resetTempLocationType()),
  onSearch: deviceName => dispatch(deviceSearch(deviceName)),
  onSelectToCompare: device => dispatch(selectDevicesForComparison(device)),
  onShowUnsupportedDevicesChange: boolean => {
    dispatch(setShowUnsupportedDevices(boolean));
    dispatch(loadDeviceResults());
  },
  onSortKeyChange: sortKey => dispatch(setSortKey(sortKey)),
  onVoiceEnabledChange: boolean => {
    dispatch(setVoiceEnabled(boolean));
    dispatch(loadDeviceResults());
  },
  onYourDevice: device => dispatch(setYourDevice(device)),
  onZipcodeChange: zip5 => {
    dispatch(setTempLocation(zip5));
    dispatch(loadZipcodeSpeedTiers(zip5));
  },
  onInvalidModalClose: boolean => dispatch(setInvalidModalClose(boolean)),
  onEponAddress: boolean => dispatch(setIsEPONAddress(boolean)),
  onDeviceInfo: () => dispatch(redirect(deviceInfo())),
});

export const App = connect(mapStateToProps, mapDispatchToProps)(DeviceInfoPage);
