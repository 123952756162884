import {changeView} from 'src/shared/currentView/actions';
import {
  DETAILS_VIEW,
  LOADING_VIEW,
} from 'src/pages/deviceInfo/constants/views';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {accessToken} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {waitForNext} from 'src/shared/events';
import {mdiDeviceDetails} from 'src/shared/config/urls.js';
import {pushHistory} from 'src/shared/history/actions';
import {fragment} from 'src/pages/scheduleCallback/utils';
import {setPageFragment} from 'src/pages/deviceInfo/store/actions/setPageFragment';

export const LOAD_DEVICE_DETAILS_START = 'LOAD_DEVICE_DETAILS_START';
export const LOAD_DEVICE_DETAILS_SUCCESS = 'LOAD_DEVICE_DETAILS_SUCCESS';
export const LOAD_DEVICE_DETAILS_ERROR = 'LOAD_DEVICE_DETAILS_ERROR';
export const LOAD_DEVICE_DETAILS_COMPLETE = 'LOAD_DEVICE_DETAILS_COMPLETE';

export const loadDeviceDetails = devicesId => createFetchResourceAction({
  start: LOAD_DEVICE_DETAILS_START,
  success: LOAD_DEVICE_DETAILS_SUCCESS,
  error: LOAD_DEVICE_DETAILS_ERROR,
  complete: LOAD_DEVICE_DETAILS_COMPLETE,
  url: state => mdiDeviceDetails(state, devicesId),
  token: accessToken,
});

export const viewDetails = devicesId => async dispatch => {
  if (devicesId) {
    dispatch(changeView(LOADING_VIEW));
    dispatch(loadDeviceDetails(devicesId));
    await waitForNext(LOAD_DEVICE_DETAILS_COMPLETE);
    dispatch(pushHistory(changeView(DETAILS_VIEW), fragment('details')));
    dispatch(changeView(DETAILS_VIEW));
    dispatch(setPageFragment('details'));
  }
};
