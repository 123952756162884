import React from 'react';
import PropTypes from 'prop-types';
import {
  searchKeyword,
  errorMessageDiv,
} from './styles.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';

export const Message = ({
  deviceFilters,
  deviceResults,
  keyWord,
  onShowUnsupportedDevicesChange,
  yourDevice,
}) => (
  <>
    {
      keyWord && (
        <div className={searchKeyword}>
          <p>Search results for {keyWord}</p>
        </div>
      )
    }
    {
      !deviceResults
        .filter(device => {
          if (deviceFilters.showUnsupportedDevices === false) {
            return device.eolStatus === 0;
          }
          return device.eolStatus === 1;
        })
        .length && (
        <div className={errorMessageDiv}>
          {
            deviceResults.find(device => device.eolStatus === 1)
              ? (
                <>
                  <p>We could not find any devices by that name compatible with Xfinity.</p>
                  <button
                    className={linkButton}
                    onClick={() => onShowUnsupportedDevicesChange(true)}
                  >
                    <p>Show Unsupported Devices</p>
                  </button>
                </>
              )
              : (
                <>
                  <p>We didn’t find anything that matched your search.</p>
                  <p>For better results, try expanding your search filters.</p>
                </>
              )
          }
        </div>
      )
    }
    {
      yourDevice && yourDevice.eolStatus === 1 && (
        <div className={errorMessageDiv}>
          <p>{yourDevice.vendorName} {yourDevice.baseModel} is not supported. Please find compatible equipment below.</p>
        </div>
      )
    }
  </>
);

Message.propTypes = {
  deviceFilters: PropTypes.object,
  deviceResults: PropTypes.array,
  keyWord: PropTypes.string,
  onShowUnsupportedDevicesChange: PropTypes.func.isRequired,
  yourDevice: PropTypes.object,
};
