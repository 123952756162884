import React from 'react';
import PropTypes from 'prop-types';
import {
  hiddenDiv,
  filters,
  filterDiv,
  filterResult,
  filterText,
  radioBox,
  fieldsetStyle,
  intentBox,
  intentText,
  intentTitle,
  intentDescription,
  bgColor,
  warningIcon,
  planSpeed,
} from './styles.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import {
  input,
  checkbox,
} from './input.module.scss';
import {Tooltip} from '../Tooltip';
import {Accordion} from '../Accordion';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';
import WarningIcon from 'src/svgs/mdi/warning.svg';
import DefaultImg from 'src/img/mdi/internet_generic_hd.png';
import {displaySpeed} from 'src/pages/deviceInfo/utils';

export const Filters = ({
  accountSpeedTier = 0,
  deviceFilters,
  deviceResults,
  downloadSpeedTiers,
  filtersDropdown,
  keyWord,
  onBrandChange,
  onBuiltInWifiChange,
  onClearAll,
  onDocsisChannelsChange,
  onDocsisVersionChange,
  onDownloadSpeedChange,
  onShowUnsupportedDevicesChange,
  onVoiceEnabledChange,
  onYourDevice,
  showOnMobile,
  yourDevice,
  yourEquipment = [],
}) => {
  const incompatibleSpeed = (filterSpeed, accountTier, speed) => filterSpeed === speed && filterSpeed < accountTier;

  return (
    <div
      className={!showOnMobile ? `${hiddenDiv} ${filters}` : `${filters}`}
      data-tracking-parent
      data-tracking='{"containerName": "Filter"}'
    >
      {
        yourEquipment.length > 0 && (
          <>
            <div className={filterDiv}>
              <span className={filterText}>Your Equipment</span>
            </div>
            <form>
              <fieldset className={`${fieldsetStyle} ${bgColor}`}>
                <legend className={a11yHidden}>Your Equipment</legend>
                {
                  yourEquipment.map((equipment, index) => (
                    <label
                      key={index}
                      htmlFor={equipment.model}
                      className={intentBox}
                    >
                      <input
                        checked={yourDevice === equipment}
                        id={equipment.model}
                        className={input}
                        name="intent-radio"
                        onChange={() => onYourDevice(equipment)}
                        type="radio"
                        value={equipment.model}
                      />
                      <div className={intentText}>
                        {equipment.eolStatus === 1 && (
                          <WarningIcon className={warningIcon} />
                        )}
                        <img src={equipment.image || DefaultImg} alt={`${equipment.vendorName} ${equipment.baseModel}`} />
                        <div>
                          <span className={intentTitle}>{equipment.vendorName} {equipment.baseModel}</span>
                          <span className={intentDescription}>{equipment.deviceType}</span>
                        </div>
                      </div>
                    </label>
                  ))
                }
              </fieldset>

            </form>
          </>
        )
      }
      <div className={`${filterDiv} ${filterResult}`}>
        <span className={filterText}>Filter</span>
        <span>
          {deviceResults
            .filter(device => {
              if (deviceFilters.showUnsupportedDevices === false) {
                return device.eolStatus === 0;
              }
              return device.eolStatus === 1;
            }).length} results found
        </span>
        <button
          className={linkButton}
          onClick={onClearAll}
        >
          Clear All
        </button>
      </div>
      <div className={`${filterDiv} ${bgColor}`}>
        <span>DOWNLOAD SPEED
          <Tooltip title="Download Speed">
            <p>Your wired download speed refers to how fast your modem pulls data from the Internet. Once you select your plan’s wired download speed*, a device compatible with your chosen speeds will be displayed. Devices identified with speeds lower than your plan may result in poor wired and WiFi speed performance.</p>
            <p>*Actual plan speeds vary and are not guaranteed.</p>
          </Tooltip>
        </span>
        {
          downloadSpeedTiers && (
            <form>
              <fieldset className={fieldsetStyle}>
                <legend className={a11yHidden}>DOWNLOAD SPEED</legend>
                {
                  downloadSpeedTiers
                    .sort((speed1, speed2) => speed2 - speed1)
                    .map((speed, index) => (
                      <label
                        key={index}
                        htmlFor={speed}
                        className={radioBox}
                      >
                        <input
                          id={speed}
                          type="radio"
                          name="DownloadSpeed"
                          value={speed}
                          className={input}
                          checked={parseInt(deviceFilters.downloadSpeed) === speed}
                          onChange={() => onDownloadSpeedChange(speed)}
                        />
                        <span className={intentText}>
                          <span>{index === 0 ? 'Up to ' : ''}{displaySpeed(speed)}</span>
                          <span>
                            {
                              incompatibleSpeed(parseInt(deviceFilters.downloadSpeed), accountSpeedTier, speed)
                              && (
                                <Tooltip iconType="exclamation">
                                  <p>Choose equipment compatible with your plan’s current speed or higher to get the most out of your Internet service.</p>
                                </Tooltip>
                              )
                            }
                          </span>
                          <span className={planSpeed}>{speed === accountSpeedTier ? 'Your plan speed' : ''}</span>
                        </span>
                      </label>
                    ))
                }
              </fieldset>
            </form>
          )
        }
      </div>
      <div className={filterDiv}>
        <label htmlFor="voiceTelephoneEnable" className={checkbox}>
          <input
            className={input}
            type="checkbox"
            id="voiceTelephoneEnable"
            name="Voice/telephoned enable"
            checked={deviceFilters.voiceEnabled}
            onChange={() => onVoiceEnabledChange(!deviceFilters.voiceEnabled)}
          />
          <span>Voice/Telephone enabled</span>
        </label>
      </div>
      {
        keyWord && (
          <div className={filterDiv}>
            <label htmlFor="unsupportedDevice" className={checkbox}>
              <input
                type="checkbox"
                id="unsupportedDevice"
                name="Show Unsupported Devices"
                checked={deviceFilters.showUnsupportedDevices}
                onChange={() => onShowUnsupportedDevicesChange(!deviceFilters.showUnsupportedDevices)}
              />
              <span>Show Unsupported Devices</span>
            </label>
          </div>
        )
      }
      <div className={filterDiv}>
        <label htmlFor="wifi" className={checkbox}>
          <input
            type="checkbox"
            id="wifi"
            name="Built-in WiFi"
            checked={deviceFilters.builtInWifi}
            onChange={() => onBuiltInWifiChange(!deviceFilters.builtInWifi)}
          />
          <span>Built-in WiFi</span>
        </label>
      </div>
      <div className={filterDiv}>
        <Accordion
          id="brandFilter"
          title="BRAND"
          hasTooltip="false"
        >
          {
            filtersDropdown.vendorNames
              .sort((a, b) => a.localeCompare(b))
              .map((filterValue, index) => (
                <div key={index}>
                  <label htmlFor={filterValue.replace(/\s/g, '-')} className={checkbox}>
                    <input
                      type="checkbox"
                      id={filterValue.replace(/\s/g, '-')}
                      name="brand"
                      value={filterValue}
                      checked={deviceFilters.brand.includes(filterValue)}
                      onChange={() => onBrandChange(filterValue)}
                    />
                    <span>{filterValue}</span>
                  </label>
                </div>
              ))
          }
        </Accordion>
      </div>
      <div className={filterDiv}>
        <Accordion
          id="DocsisChannelsFilter"
          title="DOCSIS CHANNELS"
          hasTooltip="true"
          tooltipTitle="DOCSIS Channels"
          tooltipDescription="DOCSIS downstream and upstream channels determine the maximum wired ethernet download speed and upload speed a specific device will allow.
          Choose a device with DOCSIS channels that work with your Internet plan's download speed.  By default, all Comcast-certified devices on this site will
          support all Comcast Internet plan upload speeds.  Keep in mind that selecting a device with more downstream channels than your plan requires
          won't increase your download speed."
        >
          {
            filtersDropdown.docsisChannels
              .map((filterValue, index) => (
                <div key={index}>
                  <label htmlFor={filterValue.replace(/\s/g, '-')} className={checkbox}>
                    <input
                      type="checkbox"
                      id={filterValue.replace(/\s/g, '-')}
                      name="docsis-channels"
                      value={filterValue}
                      checked={deviceFilters.docsisChannels.includes(filterValue)}
                      onChange={() => onDocsisChannelsChange(filterValue)}
                    />
                    <span>{filterValue}</span>
                  </label>
                </div>
              ))
          }
        </Accordion>
      </div>
      <div className={filterDiv}>
        <Accordion
          id="DocsisVersionFilter"
          title="DOCSIS VERSION"
          hasTooltip="true"
          tooltipTitle="DOCSIS"
          tooltipDescription="DOCSIS is the acronym for Data Over Cable Service Internet Specification. It's the international standard used for hardware that
          provides Internet service over cable TV."
        >
          {
            (keyWord ? filtersDropdown.fullDocsisVersions : filtersDropdown.docsisVersions)
              .sort((filter1, filter2) => filter2.localeCompare(filter1))
              .map((filterValue, index) => (
                <div key={index}>
                  <label htmlFor={filterValue} className={checkbox}>
                    <input
                      type="checkbox"
                      id={filterValue}
                      name="docsis-version"
                      value={filterValue}
                      checked={deviceFilters.docsisVersion.includes(filterValue)}
                      onChange={() => onDocsisVersionChange(filterValue)}
                    />
                    <span>{`${filterValue.slice(0, 6)} ${filterValue.slice(6)}`}</span>
                  </label>
                </div>
              ))
          }
        </Accordion>
      </div>
    </div>
  );
};

Filters.propTypes = {
  accountSpeedTier: PropTypes.number,
  deviceFilters: PropTypes.object,
  deviceResults: PropTypes.array,
  downloadSpeedTiers: PropTypes.array,
  filtersDropdown: PropTypes.object,
  keyWord: PropTypes.string,
  onBrandChange: PropTypes.func.isRequired,
  onBuiltInWifiChange: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onDocsisChannelsChange: PropTypes.func.isRequired,
  onDocsisVersionChange: PropTypes.func.isRequired,
  onDownloadSpeedChange: PropTypes.func.isRequired,
  onShowUnsupportedDevicesChange: PropTypes.func.isRequired,
  onVoiceEnabledChange: PropTypes.func.isRequired,
  onYourDevice: PropTypes.func.isRequired,
  showOnMobile: PropTypes.bool,
  yourDevice: PropTypes.object,
  yourEquipment: PropTypes.array,
};
