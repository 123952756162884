exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".expandable-a5421{cursor:pointer;margin:0;padding:0;position:relative;width:100%}.expandable-a5421 .expandableContainer-fe157{display:flex}.expandable-a5421 .expandableContainer-fe157:focus{border:.1rem dashed #0272b6;border-radius:.3rem}.expandable-a5421 .expandableContainer-fe157 .expandableTitle-29292{float:left}.expandable-a5421 .expandableContainer-fe157 .expandableTitle-29292>button{color:#000}.expandable-a5421 .expandableContainer-fe157 .expandableExpander-50e2e{background:rgba(0,0,0,0);border:0;cursor:pointer;display:inline-block;flex-grow:1;height:2.6rem;vertical-align:middle}.expandable-a5421 .expandableContainer-fe157 .expandableExpander-50e2e>svg{color:#b1b0bf;fill:none;height:1.8rem;margin:0;position:absolute;right:1.2rem;stroke:#0272b6;stroke-width:2;top:1.5rem;transform:translateY(-50%);transform-origin:50% 50%;transition:transform .2s ease-out;width:1.8rem}.expandable-a5421 .expandableContainer-fe157 .expandableExpander-50e2e[aria-expanded=true] svg{transform:translateY(-50%) rotate(-180deg)}.expandable-a5421 .expandableContainer-fe157 .expandableExpander-50e2e:focus{border:.1rem dashed #0272b6;border-radius:.3rem;outline:none}.accordionBody-756bb{display:none}.accordionBody-756bb[aria-hidden=false]{display:block;flex-direction:row}.accordionBody-756bb[aria-hidden=false]>div{margin-top:.75rem}", ""]);

// Exports
exports.locals = {
	"expandable": "expandable-a5421",
	"expandableContainer": "expandableContainer-fe157",
	"expandableTitle": "expandableTitle-29292",
	"expandableExpander": "expandableExpander-50e2e",
	"accordionBody": "accordionBody-756bb"
};