import React, {useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  equipIcon,
  hiddenDiv,
  hoverBox,
  listings,
  loading,
  loadingCircle,
  questionMarkIcon,
  services,
  servicesColDiv,
  showMoreDiv,
} from './styles.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import {ListingCard} from '../ListingCard';
import QuestionMarkIcon from 'src/svgs/mdi/questionMark.svg';
import RentEquipIcon from 'src/svgs/mdi/rentEquipIcon.svg';
import {LoadingCircle} from 'src/shared/components/LoadingCircle';

export const Listings = ({
  deviceFilters,
  deviceResults,
  devicesForComparison,
  forwardRef,
  isLoadingDevices,
  onDetails,
  onSelectToCompare,
  showOnMobile,
  sortKey,
  yourDevice,
}) => {
  const [showMore, setShowMore] = useState(false);
  const limit = !isEmpty(yourDevice) ? 5 : 6;
  const showMoreLimit = !showMore ? limit : deviceResults.length;

  switch (isLoadingDevices) {
    case true:
      return (
        <div className={loading}>
          <LoadingCircle className={loadingCircle} />
        </div>
      );
    case false:
    default:
      return (
        <>
          <div
            className={showOnMobile ? `${hiddenDiv} ${listings}` : `${listings}`}
            ref={forwardRef}
          >
            {
              !isEmpty(yourDevice) && (
                <ListingCard
                  device={yourDevice}
                  deviceFilters={deviceFilters}
                  devicesForComparison={devicesForComparison}
                  onSelectToCompare={onSelectToCompare}
                  onDetails={onDetails}
                  yourDevice={true}
                />
              )
            }
            {
              deviceResults.length > 0 &&
              (
                deviceResults
                  .sort((device1, device2) => {
                    if (sortKey === 'deviceNames') {
                      return device1.vendorName.localeCompare(device2.vendorName)
                      || device1.baseModel.localeCompare(device2.baseModel);
                    }
                    return device2.dsSpeed - device1.dsSpeed
                    || device2.docsisVersion.localeCompare(device1.docsisVersion)
                    || device1.dsBonding - device2.dsBonding
                    || device1.usBonding - device2.usBonding
                    || device1.vendorName.localeCompare(device2.vendorName)
                    || device2.baseModel.localeCompare(device1.baseModel);
                  })
                  .filter(device => {
                    if (deviceFilters.showUnsupportedDevices === false) {
                      return device.eolStatus === 0;
                    }
                    return device.eolStatus === 1;
                  })
                  .slice(0, showMoreLimit)
                  .map((device, index) => (
                    <ListingCard
                      key={index}
                      device={device}
                      devicesForComparison={devicesForComparison}
                      onSelectToCompare={onSelectToCompare}
                      onDetails={onDetails}
                    />
                  ))
              )
            }
            {
              deviceResults
                .filter(device => {
                  if (deviceFilters.showUnsupportedDevices === false) {
                    return device.eolStatus === 0;
                  }
                  return device.eolStatus === 1;
                })
                .length > limit && (
                <button
                  className={`${showMoreDiv} ${linkButton}`}
                  onClick={() => {
                    setShowMore(!showMore);
                    showMore ? window.scrollTo(0, 0) : null;
                  }}
                >
                  {
                    showMore
                      ? <div>Show less devices</div>
                      : <div>Show more devices</div>
                  }
                </button>
              )
            }
            <div className={services}>
              <a
                href="https://www.xfinity.com/support/articles/list-of-approved-cable-modems/"
                rel="noopener noreferrer"
                target="_blank"
                className={hoverBox}
              >
                <div className={servicesColDiv}>
                  <QuestionMarkIcon className={questionMarkIcon} />
                  <p>Get Help</p>
                </div>
              </a>
              <a
                href="https://www.xfinity.com/learn/internet-service/modems-and-routers"
                className={hoverBox}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className={servicesColDiv}>
                  <RentEquipIcon className={equipIcon} />
                  <p>Rent an xFi Gateway</p>
                </div>
              </a>
            </div>
          </div>
        </>
      );
  }
};

Listings.propTypes = {
  deviceFilters: PropTypes.shape({
    showUnsupportedDevices: PropTypes.bool,
  }),
  devicesForComparison: PropTypes.array,
  deviceResults: PropTypes.array,
  forwardRef: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  isLoadingDevices: PropTypes.bool,
  onDetails: PropTypes.func.isRequired,
  onSelectToCompare: PropTypes.func.isRequired,
  showOnMobile: PropTypes.bool,
  sortKey: PropTypes.string,
  yourDevice: PropTypes.object,
};
