import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {DeviceSearchHeader} from '../DeviceSearchHeader';
import OutageIcon from 'src/svgs/status/error_orange_36@1x.svg';
import {
  message,
  section,
} from './styles.module.scss';
import {button, primary} from 'src/styles/modules/button.module.scss';

export const EponErrorMessage = ({
  accountSpeedTier,
  addressSpeedTiers,
  addressSuggestions,
  disabledSpeedTiers,
  isLoadingSpeedTiers,
  location,
  locationErrorMessage,
  onAddressChange,
  onClearAddressSuggestions,
  onDisabledSpeedTiers,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onTempLocationTypeReset,
  onSearch,
  onZipcodeChange,
  onDeviceInfo,
  zipcodeAutofill,
  zipcodeSpeedTiers,
}) => {
  const elem = useRef(null);
  useEffect(() => {
    elem.current.dispatchEvent(
      new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [{
            componentInfo: {
              componentID: 'epon-error',
              componentName: 'EponErrorMessage',
              description: 'epon address',
              instanceID: accountSpeedTier ? 'auth' : 'unauth',
            },
          }],
        },
      })
    );
  }, []);
  return (
    <section className={section} ref={elem} data-tracking='{"containerName": "Epon Error"}'>
      <DeviceSearchHeader
        addressSpeedTiers={addressSpeedTiers}
        addressSuggestions={addressSuggestions}
        disabledSpeedTiers={disabledSpeedTiers}
        isLoadingSpeedTiers={isLoadingSpeedTiers}
        location={location}
        locationErrorMessage={locationErrorMessage}
        onAddressChange={onAddressChange}
        onClearAddressSuggestions={onClearAddressSuggestions}
        onDisabledSpeedTiers={onDisabledSpeedTiers}
        onFindDevices={onFindDevices}
        onInvalidModalClose={onInvalidModalClose}
        onLoadAddressSuggestions={onLoadAddressSuggestions}
        onTempLocationTypeChange={onTempLocationTypeChange}
        onTempLocationTypeReset={onTempLocationTypeReset}
        onSearch={onSearch}
        onZipcodeChange={onZipcodeChange}
        onDeviceInfo={onDeviceInfo}
        showSearch={false}
        zipcodeAutofill={zipcodeAutofill}
        zipcodeSpeedTiers={zipcodeSpeedTiers}
      />
      <div className={message}>
        <OutageIcon />
        <h1>Your area has a Fiber-Only network</h1>
        <p>This is a Fiber-Only network location that has a faster Internet connection, with fast download
        and dramatically faster upload speeds, access to more advanced TV service, and a
        more reliable network. At this time, there are no current retail modems on the
        market that work with this new technology. Visit our FAQs for more
        information about available services in your home.
        </p>
        <button
          type="button"
          className={`${button} ${primary}`}
        >
          <a href="https://www.xfinity.com/support/articles/all-ip-xfinity-fiber-only-network" rel="noopener">Visit FAQs</a>
        </button>
      </div>
    </section>
  );
};

EponErrorMessage.propTypes = {
  accountSpeedTier: PropTypes.number,
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func.isRequired,
  onDeviceInfo: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onFindDevices: PropTypes.func.isRequired,
  onInvalidModalClose: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onTempLocationTypeReset: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};
