import {changeView} from 'src/shared/currentView/actions';
import {
  COMPARISON_VIEW,
  LOADING_VIEW,
} from '../../constants/views';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {accessToken} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {waitForNext} from 'src/shared/events';
import {mdiDeviceCompare} from 'src/shared/config/urls.js';
import {pushHistory} from 'src/shared/history/actions';
import {fragment} from 'src/pages/scheduleCallback/utils';
import {setPageFragment} from 'src/pages/deviceInfo/store/actions/setPageFragment';

export const UPDATE_DEVICES_FOR_COMPARISION = 'UPDATE_DEVICES_FOR_COMPARISION';
export const CLEAR_ALL_DEVICES_FOR_COMPARISION = 'CLEAR_ALL_DEVICES_FOR_COMPARISION';

export const LOAD_COMPARE_DEVICES_START = 'LOAD_COMPARE_DEVICES_START';
export const LOAD_COMPARE_DEVICES_SUCCESS = 'LOAD_COMPARE_DEVICES_SUCCESS';
export const LOAD_COMPARE_DEVICES_ERROR = 'LOAD_COMPARE_DEVICES_ERROR';
export const LOAD_COMPARE_DEVICES_COMPLETE = 'LOAD_COMPARE_DEVICES_COMPLETE';

export const selectDevicesForComparison = devicesForComparison => ({
  type: UPDATE_DEVICES_FOR_COMPARISION,
  value: {devicesForComparison},
});

export const clearAllDevicesForComparison = () => ({
  type: CLEAR_ALL_DEVICES_FOR_COMPARISION,
});

export const loadCompareDevices = ids => createFetchResourceAction({
  start: LOAD_COMPARE_DEVICES_START,
  success: LOAD_COMPARE_DEVICES_SUCCESS,
  error: LOAD_COMPARE_DEVICES_ERROR,
  complete: LOAD_COMPARE_DEVICES_COMPLETE,
  url: state => mdiDeviceCompare(state, ids),
  token: accessToken,
});

export const compareDevices = ids => async dispatch => {
  if (ids) {
    dispatch(changeView(LOADING_VIEW));
    dispatch(loadCompareDevices(ids));
    await waitForNext(LOAD_COMPARE_DEVICES_COMPLETE);
    dispatch(pushHistory(changeView(COMPARISON_VIEW), fragment('comparison')));
    dispatch(changeView(COMPARISON_VIEW));
    dispatch(setPageFragment('comparison'));
  }
};
