import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {button, primary} from 'src/styles/modules/button.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import {
  findDevice,
  fdColumn,
  fdInputRow,
  fdSubmitRow,
  selectWrap,
  fdInputlabel,
  fdErrorRow,
  errorContent,
  loading,
  loadingCircle,
} from './styles.module.scss';
import {LocationInput} from '../LocationInput';
import {ErrorMessageModal} from '../ErrorMessageModal';
import {
  ADDRESS,
  BULK,
  BUSINESS,
  ZIPCODE,
  address,
  zipcode,
} from 'src/pages/deviceInfo/constants/locationTypes';
import {LoadingCircle} from 'src/shared/components/LoadingCircle';
import {displaySpeed} from 'src/pages/deviceInfo/utils';

export const FindDevice = ({
  addressSpeedTiers = [],
  addressSuggestions,
  disabledSpeedTiers,
  isLoadingSpeedTiers,
  location,
  locationErrorMessage,
  onAddressChange,
  onClearAddressSuggestions,
  onDisabledSpeedTiers,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onZipcodeChange,
  zipcodeAutofill,
  zipcodeSpeedTiers = [],
}) => {
  const [speed, setSpeed] = useState('');
  const [showBulk, setShowBulk] = useState(false);
  const [noContentMessage, setNoContentMessage] = useState(false);
  const inputRef = useRef(null);

  const handleSelectChange = event => {
    setSpeed(parseInt(event.target.value));
  };

  const continueToFindDevices = () => {
    document.body.style.overflow = 'auto';
    if (locationErrorMessage === BULK) {
      setShowBulk(true);
    } else {
      onFindDevices(speed);
    }
  };

  const onErrorModalClose = locationType => {
    onTempLocationTypeChange(locationType);
    onInvalidModalClose(true);
    inputRef.current.focus();
  };

  useEffect(() => {
    if (disabledSpeedTiers) {
      setSpeed('');
    }
  }, [disabledSpeedTiers]);

  return (
    <form
      className={findDevice}
      role="search"
    >
      {isLoadingSpeedTiers && (
        <div className={loading}>
          <LoadingCircle className={loadingCircle} />
        </div>
      )}
      <div className={fdInputRow}>
        <div className={fdColumn}>
          {
            location.tempLocationType === address
              ?
              <LocationInput
                addressSuggestions={addressSuggestions}
                location={location}
                locationErrorMessage={locationErrorMessage}
                noContentMessage={noContentMessage}
                onAddressChange={onAddressChange}
                onClearAddressSuggestions={onClearAddressSuggestions}
                onDisabledSpeedTiers={onDisabledSpeedTiers}
                onLoadAddressSuggestions={onLoadAddressSuggestions}
                onTempLocationTypeChange={onTempLocationTypeChange}
                onZipcodeChange={onZipcodeChange}
                placeholder="Street Address"
                setNoContentMessage={setNoContentMessage}
                forwardedRef={inputRef}
              />
              :
              <LocationInput
                addressSuggestions={addressSuggestions}
                location={location}
                locationErrorMessage={locationErrorMessage}
                onAddressChange={onAddressChange}
                onDisabledSpeedTiers={onDisabledSpeedTiers}
                onLoadAddressSuggestions={onLoadAddressSuggestions}
                onTempLocationTypeChange={onTempLocationTypeChange}
                onZipcodeChange={onZipcodeChange}
                placeholder="Zip Code"
                setNoContentMessage={setNoContentMessage}
                zipcodeAutofill={zipcodeAutofill}
                forwardedRef={inputRef}
              />
          }
          {
            locationErrorMessage === BULK && showBulk && (
              <ErrorMessageModal
                instanceID={locationErrorMessage}
                onErrorModalClose={onErrorModalClose}
              >
                <div className={errorContent}>
                  <h1>Great news! Xfinity service is included at your address.</h1>
                  <p>We’ve partnered with your community to bring Xfinity service to you. Contact your property management to learn more.</p>
                </div>
                <button
                  className={linkButton}
                  onClick={() => {
                    onFindDevices(speed);
                    onErrorModalClose(address);
                  }}
                >
                  Continue to approved devices
                </button>
                <button
                  className={linkButton}
                  onClick={() => {
                    setShowBulk(false);
                    onErrorModalClose(address);
                  }}
                >
                  Try a different address, including apartment number
                </button>
              </ErrorMessageModal>
            )
          }
          {
            locationErrorMessage && locationErrorMessage !== BULK && (
              <ErrorMessageModal
                instanceID={locationErrorMessage}
                onErrorModalClose={onErrorModalClose}
              >
                <div className={errorContent}>
                  {
                    locationErrorMessage === ADDRESS &&
                      <>
                        <h1>We couldn’t find any details for this address</h1>
                        <p>Enter your ZIP Code to find equipment compatible with your Internet speed. Please note that not all speeds may be available at your address.</p>
                      </>
                  }
                  {locationErrorMessage === ZIPCODE &&
                    <>
                      <h1>Unfortunately, we don’t offer services in this area yet.</h1>
                      <p>Would you like to try another ZIP Code?</p>
                    </>
                  }
                  {locationErrorMessage === BUSINESS &&
                    <h1>This looks like a business address.</h1>
                  }
                </div>
                {locationErrorMessage === BUSINESS
                  ?
                  <a
                    href="https://business.comcast.com/help-and-support/internet/comcast-business-cable-modem-device-compatibility"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={`${button} ${primary}`}
                    onClick={() => onErrorModalClose(address)}
                  >
                    Shop Business Offers
                  </a>
                  :
                  <button
                    className={`${button} ${primary}`}
                    onClick={() => onErrorModalClose(zipcode)}
                  >
                    Use ZIP Code
                  </button>
                }
                <button
                  className={linkButton}
                  onClick={() => onErrorModalClose(address)}
                >
                  Try a different address, including apartment number
                </button>
              </ErrorMessageModal>
            )
          }
        </div>
        <div className={fdColumn}>
          <p className={fdInputlabel}>Download speed</p>
          <div className={selectWrap}>
            <select
              onChange={handleSelectChange}
              value={speed}
              placeholder="Select Download Speed"
              onBlur={handleSelectChange}
              disabled={disabledSpeedTiers}
            >
              <option value="" disabled>Select Download Speed</option>
              {
                location.tempLocationType === address
                  ? (
                    addressSpeedTiers.length > 0 && (
                      addressSpeedTiers
                        .sort((tier1, tier2) => tier2 - tier1)
                        .map((speed, index) => (
                          <option key={speed} value={speed}>{index === 0 ? 'Up to ' : ''}{displaySpeed(speed)}</option>
                        ))
                    )
                  )
                  : (
                    zipcodeSpeedTiers.length > 0 && (
                      zipcodeSpeedTiers
                        .sort((tier1, tier2) => tier2 - tier1)
                        .map((speed, index) => (
                          <option key={speed} value={speed}>{index === 0 ? 'Up to ' : ''}{displaySpeed(speed)}</option>
                        ))
                    )
                  )
              }
            </select>
          </div>
        </div>
      </div>
      <div className={fdErrorRow}>
        <p>
          {noContentMessage}
        </p>
      </div>
      <div className={fdSubmitRow}>
        <button
          className={`${button} ${primary}`}
          disabled={disabledSpeedTiers || !speed}
          onClick={event => {
            event.preventDefault();
            continueToFindDevices();
          }}
        >
          Find Devices
        </button>
      </div>
    </form>
  );
};

FindDevice.propTypes = {
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onFindDevices: PropTypes.func.isRequired,
  onInvalidModalClose: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};
