import React from 'react';
import PropTypes from 'prop-types';
import {
  hiddenDiv,
  sortBy,
  selectWrap,
} from './styles.module.scss';

export const SortBy = ({
  sortKey,
  onSortKeyChange,
  showOnMobile,
}) => (
  <div className={showOnMobile ? `${hiddenDiv} ${sortBy}` : `${sortBy}`}>
    <label htmlFor="sortby">Sort by</label>
    <div className={selectWrap}>
      <select
        id="sortby"
        name="select"
        value={sortKey}
        onBlur={event => onSortKeyChange(event.target.value)}
        onChange={event => onSortKeyChange(event.target.value)}
      >
        <option value="downloadSpeeds">Download Speeds</option>
        <option value="deviceNames">Device Names</option>
      </select>
    </div>
  </div>
);

SortBy.propTypes = {
  onSortKeyChange: PropTypes.func.isRequired,
  showOnMobile: PropTypes.bool,
  sortKey: PropTypes.string,
};
