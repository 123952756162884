import {navigate, PUSH_HISTORY} from './actions';

export const history = store => next => {
  const genId = (id => () => `id-${id++}`)(0);
  const registry = Object.create(null);

  window.addEventListener('popstate', event => {
    if (event.state && event.state.id && registry[event.state.id]) {
      store.dispatch(navigate());
      store.dispatch(registry[event.state.id]);
    }
  });

  return action => {
    next(action);

    if (action.type === PUSH_HISTORY) {
      const id = genId();
      registry[id] = action.value.action;
      const key = action.value.key;
      const record = {id, key};

      // If this is the first time that we are pushing history (`!window.history.state`)
      // then use `replaceState` so that the back button will take the user
      // to their previous page. This means that it is incumbent on the user
      // of this middleware to `pushHistory` on the first view that they show.
      if (!window.history.state || key && window.history.state.key === key) {
        window.history.replaceState(record, '', action.value.path);
      } else {
        window.history.pushState(record, '', action.value.path);
      }
    }
  };
};
