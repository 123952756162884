import {logScreenName} from 'src/shared/dataLayer/loggers';
import {CHANGE_VIEW} from 'src/shared/currentView/actions';
import isFunction from 'lodash/isFunction';

export const createDataLayerMiddleware = screenName => store => next => action => {
  next(action);
  if (action.type === CHANGE_VIEW) {
    if (isFunction(screenName)) {
      logScreenName(screenName(store.getState()));
    } else {
      logScreenName(screenName);
    }
  }
};
