import {setKeyWord} from 'src/pages/deviceInfo/store/actions/deviceSearch';
import {waitForNext} from 'src/shared/events';
import {fragment} from 'src/pages/scheduleCallback/utils';
import {changeView} from 'src/shared/currentView/actions';
import {pushHistory} from 'src/shared/history/actions';
import {
  AUTHENTICATED_VIEW,
} from 'src/pages/deviceInfo/constants/views';
import {
  setDefaultDownloadSpeed,
  setDownloadSpeed,
} from 'src/pages/deviceInfo/store/actions/setDownloadSpeed';
import {
  loadDeviceResults,
  LOAD_DEVICES_RESULTS_COMPLETE,
  setAddress,
  setZipcode,
} from 'src/pages/deviceInfo/store/actions/findDevices';
import {setDownloadSpeedTiers} from 'src/pages/deviceInfo/store/actions/setDownloadSpeedTiers';
import {
  accountAddress,
  accountSpeedTier,
  accountZipcode,
  locationType,
  pageFragment,
  addressValid,
  tempDownloadSpeedTiers,
  downloadSpeedTiers,
} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {setClearAll} from './setClearAll';
import md5 from 'md5';
import {address} from 'src/pages/deviceInfo/constants/locationTypes';
import {setVoiceEnabled} from 'src/pages/deviceInfo/store/actions/setVoiceEnabled';
import {query} from 'src/shared/params/selectors';

export const SET_PAGE_FRAGMENT = 'SET_PAGE_FRAGMENT';

export const setPageFragment = pageFragment => ({
  type: SET_PAGE_FRAGMENT,
  value: {pageFragment},
});

export const setAuthFragment = page => async (dispatch, getState) => {
  const previousPage = pageFragment(getState());
  if (previousPage !== 'details' && previousPage !== 'comparison') {
    dispatch(setClearAll());
    dispatch(setDownloadSpeed(parseInt(query(getState()).speed, 10) || accountSpeedTier(getState())));
  }
  if (locationType(getState()) === address) {
    if (getState()?.elocAddress?.fullStreetAddress) {
      dispatch(setAddress(getState().elocAddress.fullStreetAddress));
    } else if (query(getState()).address && addressValid(getState()).validAddress) {
      dispatch(setAddress(query(getState()).address));
    } else {
      dispatch(setAddress(accountAddress(getState())));
    }
    dispatch(setZipcode(accountZipcode(getState())));
  } else {
    dispatch(setZipcode(query(getState()).zip || accountZipcode(getState())));
  }
  dispatch(setVoiceEnabled(query(getState()).voice === 'true'));
  dispatch(setPageFragment(page));
  dispatch(setKeyWord(null));
  dispatch(loadDeviceResults());
  dispatch(setDownloadSpeedTiers(tempDownloadSpeedTiers(getState())));
  if (!downloadSpeedTiers(getState()).includes(parseInt(query(getState()).speed, 10))) {
    dispatch(setDefaultDownloadSpeed(accountSpeedTier(getState())));
    dispatch(setDownloadSpeed(accountSpeedTier(getState())));
  }
  await waitForNext(LOAD_DEVICES_RESULTS_COMPLETE);
  dispatch(pushHistory(
    setAuthFragment(page),
    fragment(page),
    md5(page)
  ));
  return dispatch(changeView(AUTHENTICATED_VIEW));
};
