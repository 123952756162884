import {combineReducers} from 'redux';
import {
  SET_DEFAULT_DOWNLOAD_SPEED,
  SET_DOWNLOAD_SPEED,
} from '../actions/setDownloadSpeed';
import {SET_VOICE_ENABLED} from '../actions/setVoiceEnabled';
import {SET_SHOW_UNSUPPORTED_DEVICES} from '../actions/setShowUnsupportedDevices';
import {SET_BUILT_IN_WIFI} from '../actions/setBuiltInWifi';
import {TOGGLE_BRAND} from '../actions/setBrand';
import {TOGGLE_DOCSIS_CHANNELS} from '../actions/setDocsisChannels';
import {TOGGLE_DOCSIS_VERSION} from '../actions/setDocsisVersion';
import {SET_SORT_KEY} from '../actions/setSortKey';
import {CLEAR_ALL} from '../actions/setClearAll';
import {createResourceReducer} from 'src/shared/utils/resource';
import {
  LOAD_DEVICE_AUTOFILL_START,
  LOAD_DEVICE_AUTOFILL_SUCCESS,
  LOAD_DEVICE_AUTOFILL_ERROR,
  LOAD_DEVICE_AUTOFILL_COMPLETE,
  LOAD_ELOC_ID_START,
  LOAD_ELOC_ID_SUCCESS,
  LOAD_ELOC_ID_ERROR,
  LOAD_ELOC_ID_COMPLETE,
  LOAD_FILTERS_DROPDOWN_START,
  LOAD_FILTERS_DROPDOWN_SUCCESS,
  LOAD_FILTERS_DROPDOWN_ERROR,
  LOAD_FILTERS_DROPDOWN_COMPLETE,
  SET_KEY_WORD,
} from 'src/pages/deviceInfo/store/actions/deviceSearch';
import {
  CLEAR_ADDRESS_SUGGESTIONS,
  LOAD_ADDRESS_SPEED_TIERS_START,
  LOAD_ADDRESS_SPEED_TIERS_SUCCESS,
  LOAD_ADDRESS_SPEED_TIERS_ERROR,
  LOAD_ADDRESS_SPEED_TIERS_COMPLETE,
  LOAD_ADDRESS_VALID_START,
  LOAD_ADDRESS_VALID_SUCCESS,
  LOAD_ADDRESS_VALID_ERROR,
  LOAD_ADDRESS_VALID_COMPLETE,
  LOAD_ZIPCODE_SPEED_TIERS_START,
  LOAD_ZIPCODE_SPEED_TIERS_SUCCESS,
  LOAD_ZIPCODE_SPEED_TIERS_ERROR,
  LOAD_ZIPCODE_SPEED_TIERS_COMPLETE,
  LOAD_ADDRESS_SUGGESTIONS_START,
  LOAD_ADDRESS_SUGGESTIONS_SUCCESS,
  LOAD_DEVICES_RESULTS_START,
  LOAD_DEVICES_RESULTS_SUCCESS,
  LOAD_DEVICES_RESULTS_ERROR,
  LOAD_DEVICES_RESULTS_COMPLETE,
  LOAD_ZIPCODE_AUTOFILL_START,
  LOAD_ZIPCODE_AUTOFILL_SUCCESS,
  LOAD_ZIPCODE_AUTOFILL_ERROR,
  LOAD_ZIPCODE_AUTOFILL_COMPLETE,
  SET_ADDRESS,
  SET_INVALID_MODAL_CLOSE,
  SET_IS_EPON_ADDRESS,
  SET_LOCATION_TYPE,
  SET_TEMP_LOCATION,
  SET_TEMP_LOCATION_TYPE,
  SET_ZIPCODE,
} from 'src/pages/deviceInfo/store/actions/findDevices';
import {SET_YOUR_DEVICE} from 'src/pages/deviceInfo/store/actions/setYourDevice';
import {
  LOAD_DEVICE_DETAILS_START,
  LOAD_DEVICE_DETAILS_SUCCESS,
  LOAD_DEVICE_DETAILS_ERROR,
  LOAD_DEVICE_DETAILS_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/viewDetails';
import {
  CLEAR_ALL_DEVICES_FOR_COMPARISION,
  LOAD_COMPARE_DEVICES_START,
  LOAD_COMPARE_DEVICES_SUCCESS,
  LOAD_COMPARE_DEVICES_ERROR,
  LOAD_COMPARE_DEVICES_COMPLETE,
  UPDATE_DEVICES_FOR_COMPARISION,
} from 'src/pages/deviceInfo/store/actions/compareDevices';
import {
  GET_MDI_SESSION_START,
  GET_MDI_SESSION_SUCCESS,
  GET_MDI_SESSION_ERROR,
  GET_MDI_SESSION_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/getMDISession';
import {SET_DISABLED_SPEED_TIERS} from 'src/pages/deviceInfo/store/actions/setDisabledSpeedTiers';
import {
  GET_MDI_USER_SESSION_START,
  GET_MDI_USER_SESSION_SUCCESS,
  GET_MDI_USER_SESSION_ERROR,
  GET_MDI_USER_SESSION_COMPLETE,
  READ_MDI_USER_ACCOUNT_PROFILE_START,
  READ_MDI_USER_ACCOUNT_PROFILE_SUCCESS,
  READ_MDI_USER_ACCOUNT_PROFILE_ERROR,
  READ_MDI_USER_ACCOUNT_PROFILE_COMPLETE,
  READ_MDI_USER_ACCOUNT_DEVICES_START,
  READ_MDI_USER_ACCOUNT_DEVICES_SUCCESS,
  READ_MDI_USER_ACCOUNT_DEVICES_ERROR,
  READ_MDI_USER_ACCOUNT_DEVICES_COMPLETE,
  READ_MDI_USER_ACCOUNT_SPEEDTIER_START,
  READ_MDI_USER_ACCOUNT_SPEEDTIER_SUCCESS,
  READ_MDI_USER_ACCOUNT_SPEEDTIER_ERROR,
  READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/readMDIUserAccount';
import {SET_DOWNLOAD_SPEED_TIERS} from 'src/pages/deviceInfo/store/actions/setDownloadSpeedTiers';
import {SET_PAGE_FRAGMENT} from 'src/pages/deviceInfo/store/actions/setPageFragment';
import {SET_ADDRESS_ERROR} from 'src/pages/deviceInfo/store/actions/setAddressError';

export const addressSpeedTiers = createResourceReducer({
  start: LOAD_ADDRESS_SPEED_TIERS_START,
  success: LOAD_ADDRESS_SPEED_TIERS_SUCCESS,
  error: LOAD_ADDRESS_SPEED_TIERS_ERROR,
  complete: LOAD_ADDRESS_SPEED_TIERS_COMPLETE,
  initialState: [],
});

export const addressSuggestions = (state = [], action) => {
  switch (action.type) {
    case LOAD_ADDRESS_SUGGESTIONS_SUCCESS:
      return action.value.resource;
    case CLEAR_ADDRESS_SUGGESTIONS:
      return [];
    default:
      return state;
  }
};

export const addressValid = createResourceReducer({
  start: LOAD_ADDRESS_VALID_START,
  success: LOAD_ADDRESS_VALID_SUCCESS,
  error: LOAD_ADDRESS_VALID_ERROR,
  complete: LOAD_ADDRESS_VALID_COMPLETE,
});

export const customerTypeReset = (state = false, action) => {
  switch (action.type) {
    case LOAD_ADDRESS_VALID_SUCCESS:
      return true;
    case LOAD_ADDRESS_VALID_START:
      return false;
    case SET_TEMP_LOCATION_TYPE:
      return false;
    default:
      return state;
  }
};

export const comparedDevices = createResourceReducer({
  start: LOAD_COMPARE_DEVICES_START,
  success: LOAD_COMPARE_DEVICES_SUCCESS,
  error: LOAD_COMPARE_DEVICES_ERROR,
  complete: LOAD_COMPARE_DEVICES_COMPLETE,
  initialState: [],
});

export const deviceAutofill = createResourceReducer({
  start: LOAD_DEVICE_AUTOFILL_START,
  success: LOAD_DEVICE_AUTOFILL_SUCCESS,
  error: LOAD_DEVICE_AUTOFILL_ERROR,
  complete: LOAD_DEVICE_AUTOFILL_COMPLETE,
  initialState: [],
});

export const deviceDetails = createResourceReducer({
  start: LOAD_DEVICE_DETAILS_START,
  success: LOAD_DEVICE_DETAILS_SUCCESS,
  error: LOAD_DEVICE_DETAILS_ERROR,
  complete: LOAD_DEVICE_DETAILS_COMPLETE,
  initialState: [],
});

export const deviceResults = createResourceReducer({
  start: LOAD_DEVICES_RESULTS_START,
  success: LOAD_DEVICES_RESULTS_SUCCESS,
  error: LOAD_DEVICES_RESULTS_ERROR,
  complete: LOAD_DEVICES_RESULTS_COMPLETE,
  initialState: [],
});

export const filtersDropdown = createResourceReducer({
  start: LOAD_FILTERS_DROPDOWN_START,
  success: LOAD_FILTERS_DROPDOWN_SUCCESS,
  error: LOAD_FILTERS_DROPDOWN_ERROR,
  complete: LOAD_FILTERS_DROPDOWN_COMPLETE,
});

export const elocAddress = createResourceReducer({
  start: LOAD_ELOC_ID_START,
  success: LOAD_ELOC_ID_SUCCESS,
  error: LOAD_ELOC_ID_ERROR,
  complete: LOAD_ELOC_ID_COMPLETE,
});

export const MDIUserAccountDevices = createResourceReducer({
  start: READ_MDI_USER_ACCOUNT_DEVICES_START,
  success: READ_MDI_USER_ACCOUNT_DEVICES_SUCCESS,
  error: READ_MDI_USER_ACCOUNT_DEVICES_ERROR,
  complete: READ_MDI_USER_ACCOUNT_DEVICES_COMPLETE,
  initialState: [],
});

export const MDIUserAccountProfile = createResourceReducer({
  start: READ_MDI_USER_ACCOUNT_PROFILE_START,
  success: READ_MDI_USER_ACCOUNT_PROFILE_SUCCESS,
  error: READ_MDI_USER_ACCOUNT_PROFILE_ERROR,
  complete: READ_MDI_USER_ACCOUNT_PROFILE_COMPLETE,
});

export const MDIUserAccountSpeedtier = createResourceReducer({
  start: READ_MDI_USER_ACCOUNT_SPEEDTIER_START,
  success: READ_MDI_USER_ACCOUNT_SPEEDTIER_SUCCESS,
  error: READ_MDI_USER_ACCOUNT_SPEEDTIER_ERROR,
  complete: READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE,
  initialState: [],
});

export const MDIUserSession = createResourceReducer({
  start: GET_MDI_USER_SESSION_START,
  success: GET_MDI_USER_SESSION_SUCCESS,
  error: GET_MDI_USER_SESSION_ERROR,
  complete: GET_MDI_USER_SESSION_COMPLETE,
});

export const session = createResourceReducer({
  start: GET_MDI_SESSION_START,
  success: GET_MDI_SESSION_SUCCESS,
  error: GET_MDI_SESSION_ERROR,
  complete: GET_MDI_SESSION_COMPLETE,
});

export const zipcodeAutofill = createResourceReducer({
  start: LOAD_ZIPCODE_AUTOFILL_START,
  success: LOAD_ZIPCODE_AUTOFILL_SUCCESS,
  error: LOAD_ZIPCODE_AUTOFILL_ERROR,
  complete: LOAD_ZIPCODE_AUTOFILL_COMPLETE,
  initialState: [],
});

export const zipcodeSpeedTiers = createResourceReducer({
  start: LOAD_ZIPCODE_SPEED_TIERS_START,
  success: LOAD_ZIPCODE_SPEED_TIERS_SUCCESS,
  error: LOAD_ZIPCODE_SPEED_TIERS_ERROR,
  complete: LOAD_ZIPCODE_SPEED_TIERS_COMPLETE,
  initialState: [],
});

export const keyWord = (state = '', action) => {
  switch (action.type) {
    case SET_KEY_WORD:
      return action.value.keyWord;
    default:
      return state;
  }
};

export const defaultDownloadSpeed = (state = null, action) => {
  switch (action.type) {
    case SET_DEFAULT_DOWNLOAD_SPEED:
      return action.value.defaultDownloadSpeed;
    default:
      return state;
  }
};

export const downloadSpeed = (state = null, action) => {
  switch (action.type) {
    case SET_DOWNLOAD_SPEED:
      return action.value.downloadSpeed;
    default:
      return state;
  }
};

export const downloadSpeedTiers = (state = [], action) => {
  switch (action.type) {
    case SET_DOWNLOAD_SPEED_TIERS:
      return action.value.downloadSpeedTiers;
    default:
      return state;
  }
};

export const voiceEnabled = (state = false, action) => {
  switch (action.type) {
    case SET_VOICE_ENABLED:
      return action.value.voiceEnabled;
    case CLEAR_ALL:
      return false;
    default:
      return state;
  }
};

export const showUnsupportedDevices = (state = false, action) => {
  switch (action.type) {
    case SET_SHOW_UNSUPPORTED_DEVICES:
      return action.value.showUnsupportedDevices;
    case CLEAR_ALL:
      return false;
    default:
      return state;
  }
};

export const builtInWifi = (state = false, action) => {
  switch (action.type) {
    case SET_BUILT_IN_WIFI:
      return action.value.builtInWifi;
    case CLEAR_ALL:
      return false;
    default:
      return state;
  }
};

export const brand = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_BRAND:
      if (state.includes(action.value.brand)) {
        return state.filter(brand => brand !== action.value.brand);
      }
      return [...state, action.value.brand];
    case CLEAR_ALL:
      return [];
    default:
      return state;
  }
};

export const docsisChannels = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_DOCSIS_CHANNELS:
      if (state.includes(action.value.docsisChannels)) {
        return state.filter(docsisChannels => docsisChannels !== action.value.docsisChannels);
      }
      return [...state, action.value.docsisChannels];
    case CLEAR_ALL:
      return [];
    default:
      return state;
  }
};

export const docsisVersion = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_DOCSIS_VERSION:
      if (state.includes(action.value.docsisVersion)) {
        return state.filter(docsisVersion => docsisVersion !== action.value.docsisVersion);
      }
      return [...state, action.value.docsisVersion];
    case CLEAR_ALL:
      return [];
    default:
      return state;
  }
};

export const devicesForComparison = (state = [], action) => {
  switch (action.type) {
    case UPDATE_DEVICES_FOR_COMPARISION:
      if (state.some(obj => obj.id === action.value.devicesForComparison.id)) {
        return (state.filter(obj => obj.id !== action.value.devicesForComparison.id));
      }
      return [...state, action.value.devicesForComparison];
    case CLEAR_ALL_DEVICES_FOR_COMPARISION:
      return [];
    default:
      return state;
  }
};

export const sortKey = (state = 'downloadSpeeds', action) => {
  switch (action.type) {
    case SET_SORT_KEY:
      return action.value.sortKey;
    default:
      return state;
  }
};

export const yourDevice = (state = {}, action) => {
  switch (action.type) {
    case SET_YOUR_DEVICE:
      return action.value.yourDevice;
    default:
      return state;
  }
};

export const locationType = (state = 'address', action) => {
  switch (action.type) {
    case SET_LOCATION_TYPE:
      return action.value.locationType;
    default:
      return state;
  }
};

export const address = (state = '', action) => {
  switch (action.type) {
    case SET_ADDRESS:
      return action.value.address;
    default:
      return state;
  }
};

export const zipcode = (state = '', action) => {
  switch (action.type) {
    case SET_ZIPCODE:
      return action.value.zipcode;
    default:
      return state;
  }
};

export const tempLocation = (state = '', action) => {
  switch (action.type) {
    case SET_TEMP_LOCATION:
      return action.value.tempLocation;
    default:
      return state;
  }
};

export const tempLocationType = (state = 'address', action) => {
  switch (action.type) {
    case SET_TEMP_LOCATION_TYPE:
      return action.value.tempLocationType;
    default:
      return state;
  }
};

export const invalidModalClose = (state = false, action) => {
  switch (action.type) {
    case SET_INVALID_MODAL_CLOSE:
      return action.value.invalidModalClose;
    case SET_DISABLED_SPEED_TIERS:
      return false;
    default:
      return state;
  }
};

export const isEPONAddress = (state = false, action) => {
  switch (action.type) {
    case SET_IS_EPON_ADDRESS:
      return action.value.isEPONAddress;
    default:
      return state;
  }
};

export const addressError = (state = false, action) => {
  switch (action.type) {
    case SET_INVALID_MODAL_CLOSE:
      return false;
    case LOAD_ADDRESS_SPEED_TIERS_ERROR:
      return true;
    case SET_ADDRESS_ERROR:
      return true;
    default:
      return state;
  }
};

export const zipcodeError = (state = false, action) => {
  switch (action.type) {
    case LOAD_ZIPCODE_SPEED_TIERS_ERROR:
      return true;
    case SET_INVALID_MODAL_CLOSE:
      return false;
    default:
      return state;
  }
};

export const disabledSpeedTiers = (state = true, action) => {
  switch (action.type) {
    case SET_DISABLED_SPEED_TIERS:
      return action.value.disabledSpeedTiers;
    case LOAD_ZIPCODE_SPEED_TIERS_START:
      return true;
    case LOAD_ZIPCODE_SPEED_TIERS_SUCCESS:
      return false;
    case LOAD_ADDRESS_SUGGESTIONS_START:
      return true;
    case LOAD_ADDRESS_VALID_SUCCESS:
      return false;
    case SET_INVALID_MODAL_CLOSE:
      return true;
    default:
      return state;
  }
};

export const pageFragment = (state = null, action) => {
  switch (action.type) {
    case SET_PAGE_FRAGMENT:
      return action.value.pageFragment;
    default:
      return state;
  }
};

export const app = combineReducers({
  deviceFilters: combineReducers({
    defaultDownloadSpeed,
    downloadSpeed,
    voiceEnabled,
    showUnsupportedDevices,
    builtInWifi,
    brand,
    docsisChannels,
    docsisVersion,
  }),
  location: combineReducers({
    locationType,
    address,
    zipcode,
    tempLocation,
    tempLocationType,
    downloadSpeedTiers,
    isEPONAddress,
  }),
  locationError: combineReducers({
    addressError,
    zipcodeError,
    invalidModalClose,
    customerTypeReset,
  }),
  devicesForComparison,
  disabledSpeedTiers,
  sortKey,
  keyWord,
  yourDevice,
  pageFragment,
});
