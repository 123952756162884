import {createSelector} from 'reselect';
import {createResourceSelector} from 'src/shared/utils/resource';
import {resourceStatus} from 'src/shared/resourceStatus/selectors';
import {
  ADDRESS,
  BULK,
  BUSINESS,
  ZIPCODE,
  address,
} from 'src/pages/deviceInfo/constants/locationTypes';

export const app = state => state.app || {};

export const account = createResourceSelector('account');
export const addressSuggestions = createResourceSelector('addressSuggestions');
export const addressSpeedTiers = createResourceSelector('addressSpeedTiers');
export const addressValid = createResourceSelector('addressValid');
export const comparedDevices = createResourceSelector('comparedDevices');
export const deviceAutofill = createResourceSelector('deviceAutofill');
export const deviceDetails = createResourceSelector('deviceDetails');
export const deviceResults = createResourceSelector('deviceResults');
export const deviceSearchResults = createResourceSelector('deviceSearchResults');
export const filtersDropdown = createResourceSelector('filtersDropdown');
export const yourEquipment = createResourceSelector('MDIUserAccountDevices');
export const MDIUserAccountProfile = createResourceSelector('MDIUserAccountProfile');
export const MDIUserAccountSpeedtier = createResourceSelector('MDIUserAccountSpeedtier');
export const MDIUserSession = createResourceSelector('MDIUserSession');
export const session = createResourceSelector('session');
export const zipcodeAutofill = createResourceSelector('zipcodeAutofill');
export const zipcodeSpeedTiers = createResourceSelector('zipcodeSpeedTiers');

export const elocId = createSelector(
  addressValid,
  addressValid => addressValid.elocId
);

export const expiresIn = createSelector(
  [session, MDIUserSession],
  (session, MDIUserSession) => session.expires_in || MDIUserSession.expires_in
);

export const accessToken = createSelector(
  [session, MDIUserSession],
  (session, MDIUserSession) => session.access_token || MDIUserSession.access_token
);

export const defaultDownloadSpeed = createSelector(
  app,
  app => app?.deviceFilters?.defaultDownloadSpeed
);

export const deviceFilters = createSelector(
  app,
  app => app.deviceFilters
);

export const devicesForComparison = createSelector(
  app,
  app => app.devicesForComparison
);

export const disabledSpeedTiers = createSelector(
  app,
  app => app.disabledSpeedTiers
);

export const invalidModalClose = createSelector(
  app,
  app => app.invalidModalClose
);

export const isEPONAddress = createSelector(
  app,
  app => app.isEPONAddress
);

export const isLoadingDevices = createSelector(
  resourceStatus,
  ({loading}) => loading.devicesResults
);

export const isLoadingSpeedTiers = createSelector(
  resourceStatus,
  ({loading}) => loading.addressValid
  || loading.addressSpeedTiers
  || loading.zipcodeSpeedTiers
);

export const keyWord = createSelector(
  app,
  app => app.keyWord
);

export const location = createSelector(
  app,
  app => app.location
);

export const locationType = createSelector(
  app,
  app => app?.location?.locationType
);

export const pageFragment = createSelector(
  app,
  app => app.pageFragment
);

export const sortKey = createSelector(
  app,
  app => app.sortKey
);

export const locationErrorMessage = createSelector(
  [addressValid, app],
  ({customerType}, {locationError}) => {
    if (!locationError.invalidModalClose) {
      if (locationError.addressError) {
        return ADDRESS;
      } else if (locationError.zipcodeError) {
        return ZIPCODE;
      } else if (customerType === BUSINESS && locationError.customerTypeReset) {
        return BUSINESS;
      } else if (customerType === BULK && locationError.customerTypeReset) {
        return BULK;
      }
    }
    return null;
  }
);

export const yourDevice = createSelector(
  app,
  app => app.yourDevice
);

// Account

export const accountAddress = createSelector(
  MDIUserAccountProfile,
  MDIUserAccountProfile => Object.values(MDIUserAccountProfile?.serviceAddress || {}).join(' ')
);

export const accountZipcode = createSelector(
  MDIUserAccountProfile,
  MDIUserAccountProfile => MDIUserAccountProfile?.serviceAddress?.zip
);

export const accountSpeedTier = createSelector(
  MDIUserAccountSpeedtier,
  MDIUserAccountSpeedtier => MDIUserAccountSpeedtier?.[0]?.accountSpeedTier || null
);
export const isSpeedtierFdxActive = createSelector(
  MDIUserAccountSpeedtier,
  MDIUserAccountSpeedtier => MDIUserAccountSpeedtier?.[0]?.accountFdxActive || false
);
export const availableSpeedTiers = createSelector(
  MDIUserAccountSpeedtier,
  MDIUserAccountSpeedtier => MDIUserAccountSpeedtier?.[0]?.accountSpeedTierInfo?.availableSpeedTiers || []
);

export const downloadSpeedTiers = createSelector(
  app,
  app => app?.location?.downloadSpeedTiers
);

export const tempDownloadSpeedTiers = createSelector(
  [
    addressSpeedTiers,
    availableSpeedTiers,
    filtersDropdown,
    location,
    pageFragment,
    zipcodeSpeedTiers,
  ],
  (
    addressSpeedTiers,
    availableSpeedTiers,
    filtersDropdown,
    location,
    pageFragment,
    zipcodeSpeedTiers
  ) => {
    switch (pageFragment) {
      case 'auth':
        return availableSpeedTiers;
      case 'results':
        if (location.tempLocationType === address && addressSpeedTiers.length > 0) {
          return addressSpeedTiers;
        }
        return zipcodeSpeedTiers;
      case 'search':
      default:
        return filtersDropdown.dsSpeeds;
    }
  }
);
