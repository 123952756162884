import React from 'react';
import PropTypes from 'prop-types';
import {
  listingCard,
  productDiv,
  productTitleDiv,
  viewDetail,
  productThumbnail,
  selectCompareDiv,
  yourDeviceDiv,
  deviceError,
  deviceTitle,
} from './styles.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import {checkbox} from '../Filters/input.module.scss';
import NotSupported from 'src/svgs/mdi/notSupported.svg';
import DefaultImg from 'src/img/mdi/internet_generic_hd.png';

export const ListingCard = ({
  device,
  deviceFilters = [],
  devicesForComparison,
  onSelectToCompare,
  onDetails,
  yourDevice,
}) => (
  <div className={listingCard}>
    <div className={productDiv}>
      {
        yourDevice && (
          <div className={yourDeviceDiv}>
            <em>Your Device</em>
          </div>
        )
      }
      <div className={productThumbnail}>
        <img src={device.image || DefaultImg} alt={`${device.vendorName} ${device.baseModel}`} />
      </div>
      <button className={`${productTitleDiv} ${linkButton}`} onClick={() => onDetails(device.id)}>
        <p className={deviceTitle}>{`${device.vendorName} ${device.baseModel}`}</p>
        <p>{device.productName}</p>
        {
          device.eolStatus === 1 && (
            <p className={deviceError}><NotSupported /> This device is not supported.</p>
          )
        }
        {
          yourDevice && (deviceFilters.downloadSpeed > parseInt(device.dsSpeed.toString().slice(0, -6))) && (
            <p className={deviceError}><NotSupported /> Incompatible based on filters</p>
          )
        }
        <p className={viewDetail}>View details</p>
      </button>
    </div>
    <div className={selectCompareDiv}>
      <label htmlFor={`device-${device.id}`} className={checkbox}>
        <input
          type="checkbox"
          id={`device-${device.id}`}
          name={device.vendorName}
          value={device.baseModel}
          onChange={() => onSelectToCompare(device)}
          checked={devicesForComparison.some(obj => obj.id === device.id)}
          disabled={devicesForComparison.length === 2 && !devicesForComparison.some(obj => obj.id === device.id)}
        />
        <span>Select to Compare</span>
      </label>
    </div>
  </div>
);

ListingCard.propTypes = {
  device: PropTypes.object.isRequired,
  deviceFilters: PropTypes.object,
  devicesForComparison: PropTypes.array,
  onSelectToCompare: PropTypes.func.isRequired,
  onDetails: PropTypes.func.isRequired,
  yourDevice: PropTypes.bool,
};
