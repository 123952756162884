import {REDIRECT, RELOAD} from './actions';
import {continueUrl} from './selectors';
import {params} from 'src/shared/params/selectors';

export const redirect = store => next => action => {
  if (action.type === RELOAD) {
    window.location.reload();
  }
  if (action.type === REDIRECT) {
    const {
      value: {
        redirectUrl,
        replace,
      },
    } = action;
    let redirect = redirectUrl || continueUrl(store.getState());
    if (!/^(http:|https:)?\/\//.test(redirect)) {
      redirect = document.baseURI + redirect;
    }
    if (params(store.getState()).combined.debugRedirectDisabled) {
      console.warn(`Redirect to "${redirect}" was supressed.`);
    } else if (replace) {
      window.location.replace(redirect);
    } else {
      window.location.assign(redirect);
    }
  }
  next(action);
};
