import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  errorModalCover,
  locationModal,
} from './styles.module.scss';
import {address} from 'src/pages/deviceInfo/constants/locationTypes';

const KEYCODE_TAB = 9;
const ESC = 27;

const modalImpression = instanceID => {
  return JSON.stringify({
    containerName: 'Find Devices',
    component: [
      {
        componentInfo: {
          componentID: 'find-devices-error',
          componentName: 'Modal',
          description: 'Modal',
          instanceID: instanceID,
        },
      },
    ],
  });
};

export const ErrorMessageModal = ({
  children,
  instanceID,
  onErrorModalClose,
}) => {
  const elem = useRef(null);

  useEffect(() => {
    document.querySelector(`div.${errorModalCover}`).querySelectorAll('button:not([disabled]), a[href]:not([disabled])')[0].focus();
    elem.current.dispatchEvent(
      new CustomEvent('c-tracking-log-dom', {
        bubbles: true,
        composed: true,
        detail: {
          eventMethod: 'component-Track',
          component: [{
            componentInfo: {
              componentID: 'find-devices-error',
              componentName: 'Modal',
              description: 'Modal',
              instanceID: instanceID,
            },
          }],
        },
      })
    );
  }, [elem]);

  useEffect(() => {
    const focusableEls = document.querySelector(`div.${errorModalCover}`).querySelectorAll('button:not([disabled]), a[href]:not([disabled])');
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];
    const listener = e => {
      if (e.keyCode === ESC) {
        onErrorModalClose(address);
      }
      if (e.key === 'Tab' || e.keyCode === KEYCODE_TAB) {
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  return ReactDOM.createPortal(
    <div
      className={errorModalCover}
      tabIndex="-1"
      aria-label="Location Error Message"
      aria-modal="true"
      role="dialog"
      data-tracking-parent
      data-tracking={modalImpression(instanceID)}
      ref={elem}
    >
      <div className={locationModal}>
        {children}
      </div>
    </div>,
    document.body
  );
};

ErrorMessageModal.propTypes = {
  children: PropTypes.node.isRequired,
  instanceID: PropTypes.string,
  onErrorModalClose: PropTypes.func,
};
