import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  fdInputlabel,
  addressSuggestionsStyle,
  addressResultItem,
  suggestionActive,
  inputWrap,
  highlightedStyle,
  switchAddress,
} from './styles.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import {
  BULK,
  address,
  zipcode,
} from 'src/pages/deviceInfo/constants/locationTypes';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';

const ENTER = 13;
const ESC = 27;
const UP_ARROW = 38;
const DOWN_ARROW = 40;
const searchLimit = 8;
const addressInputLimit = 4;
const stopChars = /[\W-]+/gi;
const wordSplitWithSpace = /\b\s+?/;
const numberChar = /^[0-9]+$|^$/;
const wordSplit = /\b\s?/;

export const LocationInput = ({
  addressSuggestions,
  forwardedRef,
  location,
  locationErrorMessage,
  onAddressChange,
  onClearAddressSuggestions,
  onDisabledSpeedTiers,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onZipcodeChange,
  placeholder,
  setNoContentMessage,
  zipcodeAutofill,
}) => {
  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [retainFocus, setRetainFocus] = useState(false);
  const showResults = results.length > 0 && (showSuggestion || retainFocus);
  const [addressError, setAddressError] = useState(false);
  const [loadedRes, setLoadedRes] = useState(false);
  const [notice, setNotice] = useState(false);

  const handleMouseDown = () => {
    setRetainFocus(true);
  };

  const handleMouseUp = () => {
    setTimeout(() => setRetainFocus(false));
  };

  const handleBlur = () => {
    setShowSuggestion(false);
  };

  const searchZipcode = val =>
    zipcodeAutofill
      .filter(data => data.includes(val))
      .slice(0, searchLimit)
      .sort((a, b) => a - b);

  const handleInputChange = event => {
    onDisabledSpeedTiers(true);
    setNotice(true);
    const searchStr = event.target.value.replace(stopChars, ' ');
    if (location.tempLocationType === address) {
      setValue(searchStr);
      if (searchStr.trim().length < addressInputLimit) {
        onClearAddressSuggestions();
        return setResults([]);
      }
      onLoadAddressSuggestions(searchStr).then(() => {
        setLoadedRes(true);
      });
      setResults(addressSuggestions);
      setShowSuggestion(true);
      setActiveSuggestion(-1);
    } else {
      if (numberChar.test(searchStr)) {
        if (searchStr.length > 5) {
          return;
        }
        setNoContentMessage('');
        setValue(searchStr);
        onDisabledSpeedTiers(true);
        if (searchStr.trim().length < 1) {
          return setResults([]);
        }
        const found = searchZipcode(searchStr);
        setShowSuggestion(true);
        setActiveSuggestion(-1);
        setResults(found);
        if (!found.length) {
          return setNoContentMessage('Unfortunately, we don’t offer services in this area yet. Would you like to try another ZIP Code?');
        }
      }
    }
  };

  const handleLocationClick = result => event => {
    event.preventDefault();
    setActiveSuggestion(-1);
    setValue(result);
    setResults([]);
    if (location.tempLocationType === address) {
      onAddressChange(result);
    } else {
      onZipcodeChange(result);
    }
  };

  const onKeyDown = event => {
    if ([ESC, UP_ARROW, DOWN_ARROW, ENTER].includes(event.keyCode)) {
      event.preventDefault();
    }
    if (event.keyCode === ENTER) {
      if (activeSuggestion === -1) {
        return;
      }
      setValue(results[activeSuggestion]);
      if (location.tempLocationType === address) {
        onAddressChange(results[activeSuggestion]);
      } else {
        onZipcodeChange(results[activeSuggestion]);
      }
      setResults([]);
    } else if (event.keyCode === UP_ARROW) {
      if (activeSuggestion === -1) {
        return setActiveSuggestion(results.length - 1);
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (event.keyCode === DOWN_ARROW) {
      if (activeSuggestion + 1 === results.length) {
        return setActiveSuggestion(-1);
      }
      setActiveSuggestion(activeSuggestion + 1);
    } else if (event.keyCode === ESC) {
      setResults([]);
    }
  };

  const highlighted = text => {
    const searchStr = value.trim()
      .replace(stopChars, ' ')
      .split(wordSplitWithSpace)
      .reduce((str, current) => `${str}(?=.*\\b.*${current}.*\\b)`, '') + '.+';
    const replaceStr = `(${value.trim().split(wordSplit).join('|')})`;
    const replaceRegexp = new RegExp(replaceStr, 'gi');
    const parts = text.split(replaceRegexp);

    return (
      <span>
        {
          parts.map((part, index) => (
            <span
              key={index}
              className={`${searchStr.toLowerCase().match(part.toLowerCase()) ? highlightedStyle : ''}`}
            >
              {part}
            </span>
          ))
        }
      </span>
    );
  };

  const locationTypeChange = (event, addressType) => {
    event.preventDefault();
    setValue('');
    setNoContentMessage('');
    onDisabledSpeedTiers(true);
    onTempLocationTypeChange(addressType);
  };

  useEffect(() => {
    if (locationErrorMessage !== BULK) {
      setValue('');
    }
  }, [locationErrorMessage]);

  useEffect(() => {
    if (loadedRes && addressSuggestions.length === 0 && value.trim().length >= addressInputLimit) {
      setAddressError(true);
      return setShowSuggestion(false);
    }
    setShowSuggestion(true);
  }, [loadedRes, addressSuggestions]);

  return (
    <>
      <p className={fdInputlabel}>{placeholder} {location.tempLocationType === address ? '(And Unit)' : ''}</p>
      <div className={inputWrap}>
        <div
          id="locationOptionCount"
          className={`announcer ${a11yHidden}`}
          aria-live="assertive"
        >
          {
            notice && <p>{`${results.length} results found`}</p>
          }
        </div>
        <label htmlFor="deviceSearchInput" className={a11yHidden}>{location.tempLocationType} search</label>
        {/* eslint-disable jsx-a11y/role-supports-aria-props */}
        <input
          id="locationSearchInput"
          aria-activedescendant={`locationList${activeSuggestion}`}
          aria-autocomplete="list"
          aria-controls="locationListbox"
          aria-describedby="locationOptionCount"
          aria-expanded={showResults}
          aria-required="true"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          name="streetAddress"
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
          ref={forwardedRef}
          role="combobox"
        />
        {
          showResults
          && (
            <div className={addressSuggestionsStyle}>
              <ul
                id="locationListbox"
                role="listbox"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                tabIndex="-1"
              >
                {
                  results.map((result, index) => (
                    <li
                      id={`locationList${index}`}
                      aria-selected={index === activeSuggestion}
                      key={index}
                      className={`${addressResultItem} ${index === activeSuggestion ? suggestionActive : ''}`}
                      onClick={handleLocationClick(result)}
                      value={result}
                      role="option"
                      onKeyDown={onKeyDown}
                    >
                      {highlighted(result)}
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
      </div>
      {
        location.tempLocationType === zipcode
          && (
            <button
              className={`${linkButton} ${switchAddress}`}
              onClick={event => locationTypeChange(event, address)}
            >
              Try a different address, including apartment number
            </button>
          )
      }
      {
        addressError && location.tempLocationType === address
          && (
            <button
              className={`${linkButton} ${switchAddress}`}
              onClick={event => locationTypeChange(event, zipcode)}
            >
              Try a zipcode
            </button>
          )
      }
    </>
  );
};

LocationInput.propTypes = {
  addressSuggestions: PropTypes.array,
  forwardedRef: PropTypes.object,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  setNoContentMessage: PropTypes.func.isRequired,
  zipcodeAutofill: PropTypes.array,
};
