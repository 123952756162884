export const SET_DOWNLOAD_SPEED = 'SET_DOWNLOAD_SPEED';
export const SET_DEFAULT_DOWNLOAD_SPEED = 'SET_DEFAULT_DOWNLOAD_SPEED';

export const setDownloadSpeed = downloadSpeed => ({
  type: SET_DOWNLOAD_SPEED,
  value: {downloadSpeed},
});

export const setDefaultDownloadSpeed = defaultDownloadSpeed => ({
  type: SET_DEFAULT_DOWNLOAD_SPEED,
  value: {defaultDownloadSpeed},
});
