import {createFetchResourceAction} from 'src/shared/utils/resource';
import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {accessToken} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {
  mdiSession,
  mdiUserAccountProfile,
  mdiUserAccountDevices,
  mdiUserAccountSpeedtier,
} from 'src/shared/config/urls.js';

export const GET_MDI_USER_SESSION_START = 'GET_MDI_USER_SESSION_START';
export const GET_MDI_USER_SESSION_COMPLETE = 'GET_MDI_USER_SESSION_COMPLETE';
export const GET_MDI_USER_SESSION_SUCCESS = 'GET_MDI_USER_SESSION_SUCCESS';
export const GET_MDI_USER_SESSION_ERROR = 'GET_MDI_USER_SESSION_ERROR';

export const READ_MDI_USER_ACCOUNT_PROFILE_START = 'READ_MDI_USER_ACCOUNT_PROFILE_START';
export const READ_MDI_USER_ACCOUNT_PROFILE_SUCCESS = 'READ_MDI_USER_ACCOUNT_PROFILE_SUCCESS';
export const READ_MDI_USER_ACCOUNT_PROFILE_ERROR = 'READ_MDI_USER_ACCOUNT_PROFILE_ERROR';
export const READ_MDI_USER_ACCOUNT_PROFILE_COMPLETE = 'READ_MDI_USER_ACCOUNT_PROFILE_COMPLETE';

export const READ_MDI_USER_ACCOUNT_DEVICES_START = 'READ_MDI_USER_ACCOUNT_DEVICES_START';
export const READ_MDI_USER_ACCOUNT_DEVICES_SUCCESS = 'READ_MDI_USER_ACCOUNT_DEVICES_SUCCESS';
export const READ_MDI_USER_ACCOUNT_DEVICES_ERROR = 'READ_MDI_USER_ACCOUNT_DEVICES_ERROR';
export const READ_MDI_USER_ACCOUNT_DEVICES_COMPLETE = 'READ_MDI_USER_ACCOUNT_DEVICES_COMPLETE';

export const READ_MDI_USER_ACCOUNT_SPEEDTIER_START = 'READ_MDI_USER_ACCOUNT_SPEEDTIER_START';
export const READ_MDI_USER_ACCOUNT_SPEEDTIER_SUCCESS = 'READ_MDI_USER_ACCOUNT_SPEEDTIER_SUCCESS';
export const READ_MDI_USER_ACCOUNT_SPEEDTIER_ERROR = 'READ_MDI_USER_ACCOUNT_SPEEDTIER_ERROR';
export const READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE = 'READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE';

export const getMDIUserSession = () => createFetchResourceAction({
  start: GET_MDI_USER_SESSION_START,
  success: GET_MDI_USER_SESSION_SUCCESS,
  error: GET_MDI_USER_SESSION_ERROR,
  complete: GET_MDI_USER_SESSION_COMPLETE,
  url: mdiSession,
  fetchOptions: {
    method: 'POST',
  },
  waitUntil: [AUTH],
  token,
});

export const readMDIUserAccountProfile = () => createFetchResourceAction({
  start: READ_MDI_USER_ACCOUNT_PROFILE_START,
  success: READ_MDI_USER_ACCOUNT_PROFILE_SUCCESS,
  error: READ_MDI_USER_ACCOUNT_PROFILE_ERROR,
  complete: READ_MDI_USER_ACCOUNT_PROFILE_COMPLETE,
  url: mdiUserAccountProfile,
  waitUntil: [GET_MDI_USER_SESSION_COMPLETE],
  token: accessToken,
});

export const readMDIUserAccountDevices = () => createFetchResourceAction({
  start: READ_MDI_USER_ACCOUNT_DEVICES_START,
  success: READ_MDI_USER_ACCOUNT_DEVICES_SUCCESS,
  error: READ_MDI_USER_ACCOUNT_DEVICES_ERROR,
  complete: READ_MDI_USER_ACCOUNT_DEVICES_COMPLETE,
  url: mdiUserAccountDevices,
  waitUntil: [GET_MDI_USER_SESSION_COMPLETE],
  token: accessToken,
});

export const readMDIUserAccountSpeedtier = () => createFetchResourceAction({
  start: READ_MDI_USER_ACCOUNT_SPEEDTIER_START,
  success: READ_MDI_USER_ACCOUNT_SPEEDTIER_SUCCESS,
  error: READ_MDI_USER_ACCOUNT_SPEEDTIER_ERROR,
  complete: READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE,
  url: mdiUserAccountSpeedtier,
  waitUntil: [GET_MDI_USER_SESSION_COMPLETE],
  token: accessToken,
});
