import {changeView} from 'src/shared/currentView/actions';
import {
  LOADING_VIEW,
  RESULTS_VIEW,
} from '../../constants/views';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {
  accessToken,
  pageFragment,
  tempDownloadSpeedTiers,
} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {
  waitForNext,
  waitUntil,
} from 'src/shared/events';
import {
  elocAddress,
  mdiDeviceAutofill,
  mdiFilterData,
} from 'src/shared/config/urls.js';
import {setClearAll} from 'src/pages/deviceInfo/store/actions/setClearAll';
import {setPageFragment} from 'src/pages/deviceInfo/store/actions/setPageFragment';
import {setDownloadSpeed} from 'src/pages/deviceInfo/store/actions/setDownloadSpeed';
import {
  loadDeviceResults,
  LOAD_DEVICES_RESULTS_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/findDevices';
import {setDownloadSpeedTiers} from 'src/pages/deviceInfo/store/actions/setDownloadSpeedTiers';
import {pushHistory} from 'src/shared/history/actions';
import md5 from 'md5';
import {fragment} from 'src/pages/scheduleCallback/utils';

export const LOAD_DEVICE_AUTOFILL_START = 'LOAD_DEVICE_AUTOFILL_START';
export const LOAD_DEVICE_AUTOFILL_SUCCESS = 'LOAD_DEVICE_AUTOFILL_SUCCESS';
export const LOAD_DEVICE_AUTOFILL_ERROR = 'LOAD_DEVICE_AUTOFILL_ERROR';
export const LOAD_DEVICE_AUTOFILL_COMPLETE = 'LOAD_DEVICE_AUTOFILL_COMPLETE';

export const LOAD_ELOC_ID_START = 'LOAD_ELOC_ID_START';
export const LOAD_ELOC_ID_SUCCESS = 'LOAD_ELOC_ID_SUCCESS';
export const LOAD_ELOC_ID_ERROR = 'LOAD_ELOC_ID_ERROR';
export const LOAD_ELOC_ID_COMPLETE = 'LOAD_ELOC_ID_COMPLETE';

export const LOAD_FILTERS_DROPDOWN_START = 'LOAD_FILTERS_DROPDOWN_START';
export const LOAD_FILTERS_DROPDOWN_SUCCESS = 'LOAD_FILTERS_DROPDOWN_SUCCESS';
export const LOAD_FILTERS_DROPDOWN_ERROR = 'LOAD_FILTERS_DROPDOWN_ERROR';
export const LOAD_FILTERS_DROPDOWN_COMPLETE = 'LOAD_FILTERS_DROPDOWN_COMPLETE';

export const SET_KEY_WORD = 'SET_KEY_WORD';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export const setKeyWord = keyWord => ({
  type: SET_KEY_WORD,
  value: {keyWord},
});

export const loadDeviceAutofill = () => createFetchResourceAction({
  start: LOAD_DEVICE_AUTOFILL_START,
  success: LOAD_DEVICE_AUTOFILL_SUCCESS,
  error: LOAD_DEVICE_AUTOFILL_ERROR,
  complete: LOAD_DEVICE_AUTOFILL_COMPLETE,
  url: mdiDeviceAutofill,
  token: accessToken,
});

export const loadAddressByEloc = elocId => createFetchResourceAction({
  start: LOAD_ELOC_ID_START,
  success: LOAD_ELOC_ID_SUCCESS,
  error: LOAD_ELOC_ID_ERROR,
  complete: LOAD_ELOC_ID_COMPLETE,
  url: state => elocAddress(state, elocId),
  token: accessToken,
});

export const loadFiltersDropdown = () => createFetchResourceAction({
  start: LOAD_FILTERS_DROPDOWN_START,
  success: LOAD_FILTERS_DROPDOWN_SUCCESS,
  error: LOAD_FILTERS_DROPDOWN_ERROR,
  complete: LOAD_FILTERS_DROPDOWN_COMPLETE,
  url: mdiFilterData,
  token: accessToken,
});

export const deviceSearchFragment = (page, deviceName) => async (dispatch, getState) => {
  const previousPage = pageFragment(getState());
  if (previousPage !== 'details' && previousPage !== 'comparison') {
    dispatch(setClearAll());
    dispatch(setDownloadSpeed(null));
  }
  dispatch(setPageFragment(page));
  dispatch(setKeyWord(deviceName));
  dispatch(loadDeviceResults());
  dispatch(setDownloadSpeedTiers(tempDownloadSpeedTiers(getState())));
  await waitForNext(LOAD_DEVICES_RESULTS_COMPLETE);
  dispatch(pushHistory(
    deviceSearchFragment(page, deviceName),
    fragment(page),
    md5(page, deviceName)
  ));
  return dispatch(changeView(RESULTS_VIEW));
};

export const deviceSearch = deviceName => async dispatch => {
  if (deviceName) {
    dispatch(changeView(LOADING_VIEW));
    dispatch(setClearAll());
    dispatch(setDownloadSpeed(null));
    await waitUntil(LOAD_FILTERS_DROPDOWN_COMPLETE);
    dispatch(deviceSearchFragment('search', deviceName));
  }
};
