import {createSelector} from 'reselect';
import {combined} from 'src/shared/params/selectors';
import {isValidRedirectUrl} from './allowlist';

const redirectUrl = state => state.redirectUrl;

export const continueUrl = createSelector(
  [combined, redirectUrl],
  (combined, redirectUrl) =>
    (isValidRedirectUrl(combined.continue) && combined.continue)
    || redirectUrl
    || document.baseURI
);
