import {CHANGE_VIEW} from './actions';
import {LOADING} from './commonViews';

export const currentView = (state = LOADING, action) => {
  switch (action.type) {
    case CHANGE_VIEW:
      return action.value.view;
    default:
      return state;
  }
};
