import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  modalBtn,
  modalCover,
  modal,
  modalClose,
  closeIcon,
  modalBody,
} from './styles.module.scss';
import CloseIcon from 'src/svgs/action/close.svg';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';

const KEYCODE_TAB = 9;
const ESC = 27;

const modalImpression = triggerText => {
  return JSON.stringify({
    containerName: 'Find Devices',
    component: [
      {
        componentInfo: {
          componentID: 'find-devices',
          componentName: 'Modal',
          description: 'Modal',
          instanceID: triggerText,
        },
      },
    ],
  });
};

export const Modal = ({
  children,
  expanded,
  onTempLocationTypeReset,
  forceClose,
  setExpanded,
  title,
  triggerText,
}) => {
  const buttonRef = useRef();
  const modalRef = useRef();
  const closeRef = useRef();
  const elem = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  });

  const setAriaHiddenOnBackground = () => {
    document.querySelector('xc-header').setAttribute('aria-hidden', true);
    document.getElementById('app').setAttribute('aria-hidden', true);
    document.querySelector('xc-footer').setAttribute('aria-hidden', true);
  };

  const removeAriaHiddenOnBackground = () => {
    document.querySelector('xc-header').removeAttribute('aria-hidden');
    document.getElementById('app').removeAttribute('aria-hidden');
    document.querySelector('xc-footer').removeAttribute('aria-hidden');
  };

  const onOpen = () => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
    setAriaHiddenOnBackground();
  };

  const onClose = () => {
    setIsOpen(false);
    onTempLocationTypeReset();
    buttonRef.current.focus();
    document.body.style.overflow = 'auto';
    removeAriaHiddenOnBackground();
  };

  useEffect(() => {
    if (expanded) {
      buttonRef.current.focus();
    }
    if (isOpen) {
      closeRef.current.focus();
      elem.current.dispatchEvent(
        new CustomEvent('c-tracking-log-dom', {
          bubbles: true,
          composed: true,
          detail: {
            eventMethod: 'component-Track',
            component: [{
              componentInfo: {
                componentID: 'find-devices',
                componentName: 'Modal',
                description: 'Modal',
                instanceID: triggerText,
              },
            }],
          },
        })
      );
    }
  }, [isOpen, expanded]);

  useEffect(() => {
    if (isOpen) {
      const focusableEls = document.querySelector(`div.${modal}`).querySelectorAll('button:not([disabled]), input[type="search"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      const listener = event => {
        if (event.keyCode === ESC) {
          onClose();
        }
        if (event.key === 'Tab' || event.keyCode === KEYCODE_TAB) {
          // istanbul ignore if
          if (event.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
              lastFocusableEl.focus();
              event.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusableEl) {
              firstFocusableEl.focus();
              event.preventDefault();
            }
          }
        }
      };
      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  });

  return (
    <div
      data-tracking-parent
      data-tracking={modalImpression(triggerText)}
      ref={elem}
      aria-live="polite"
    >
      <button
        className={`${modalBtn} ${linkButton}`}
        onClick={onOpen}
        ref={buttonRef}
        onFocus={() => setExpanded(true)}
        onBlur={() => setExpanded(false)}
      >
        {triggerText}
      </button>
      {
        isOpen && ReactDOM.createPortal(
          <div
            aria-modal="true"
            className={modalCover}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="locationModalTitle"
          >
            <div className={modal} ref={modalRef}>
              <button
                className={`${modalClose} ${linkButton}`}
                aria-label="Close Modal"
                onClick={onClose}
                ref={closeRef}
              >
                <span className={a11yHidden}>Close location modal</span>
                <CloseIcon className={closeIcon} />
              </button>
              <div className={modalBody}>
                <h1 id="locationModalTitle">{title}</h1>
                {children}
              </div>
            </div>
          </div>,
          document.body
        )
      }
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  forceClose: PropTypes.bool,
  onTempLocationTypeReset: PropTypes.func,
  setExpanded: PropTypes.func,
  title: PropTypes.string,
  triggerText: PropTypes.string,
};
