exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fdInputlabel-a1f08{color:#44484c;font:300 1.6rem/2.4rem XfinityStandard,Helvetica,Arial,sans-serif;letter-spacing:.02rem;color:#191919;margin:0 0 .6rem 0;text-align:left}.fdInputlabel-a1f08 strong{font-weight:500}.inputWrap-17085{position:relative;width:100%}.inputWrap-17085 input:focus::-webkit-input-placeholder{opacity:0;-webkit-transition:opacity .5s .5s ease;transition:opacity .5s .5s ease}.inputWrap-17085 .addressSuggestionsStyle-4de42{background-color:#fff;border:.1rem solid #b1b0bf;border-radius:0 0 .3rem .3rem;border-top:0;box-sizing:border-box;color:#191919;display:block;margin-left:0;position:absolute;width:100%;z-index:4}.inputWrap-17085 .addressSuggestionsStyle-4de42 ul{list-style:none;margin:0;padding:0;width:100%;z-index:4}.inputWrap-17085 .addressSuggestionsStyle-4de42 ul .addressResultItem-1143e{color:#44484c;font:300 1.6rem/2.4rem XfinityStandard,Helvetica,Arial,sans-serif;letter-spacing:.02rem;display:block;padding:.5rem 2.5rem;text-align:left}.inputWrap-17085 .addressSuggestionsStyle-4de42 ul .addressResultItem-1143e strong{font-weight:500}.inputWrap-17085 .addressSuggestionsStyle-4de42 ul .addressResultItem-1143e .highlightedStyle-01f41{color:#0b7dbb;font-weight:800}.inputWrap-17085 .addressSuggestionsStyle-4de42 ul .addressResultItem-1143e:first-child{border-top:0}.inputWrap-17085 .addressSuggestionsStyle-4de42 ul .addressResultItem-1143e.suggestionActive-9cc89,.inputWrap-17085 .addressSuggestionsStyle-4de42 ul .addressResultItem-1143e:hover{background-color:#f2f4f5;cursor:pointer}.switchAddress-e5a4e{float:right}", ""]);

// Exports
exports.locals = {
	"fdInputlabel": "fdInputlabel-a1f08",
	"inputWrap": "inputWrap-17085",
	"addressSuggestionsStyle": "addressSuggestionsStyle-4de42",
	"addressResultItem": "addressResultItem-1143e",
	"highlightedStyle": "highlightedStyle-01f41",
	"suggestionActive": "suggestionActive-9cc89",
	"switchAddress": "switchAddress-e5a4e"
};