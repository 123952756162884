import React from 'react';
import PropTypes from 'prop-types';
import {
  icon} from 'src/pages/repair/components/ErrorScreen/style.module.scss';
import {button, buttonGroup, primary} from 'src/styles/modules/button.module.scss';
import Exclamation from 'src/svgs/status/exclamation.svg';
import {DeviceSearchHeader} from '../DeviceSearchHeader';
import {disclaimerCard, content} from './styles.module.scss';

export const FdxDisclaimer = ({
  addressSpeedTiers,
  addressSuggestions,
  disabledSpeedTiers,
  isLoadingSpeedTiers,
  location,
  locationErrorMessage,
  onAddressChange,
  onClearAddressSuggestions,
  onDeviceInfo,
  onDisabledSpeedTiers,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onTempLocationTypeReset,
  onSearch,
  onZipcodeChange,
  zipcodeAutofill,
  zipcodeSpeedTiers,
}) => {
  return (
    <>
      <DeviceSearchHeader
        addressSpeedTiers={addressSpeedTiers}
        addressSuggestions={addressSuggestions}
        disabledSpeedTiers={disabledSpeedTiers}
        isLoadingSpeedTiers={isLoadingSpeedTiers}
        location={location}
        locationErrorMessage={locationErrorMessage}
        onAddressChange={onAddressChange}
        onClearAddressSuggestions={onClearAddressSuggestions}
        onDisabledSpeedTiers={onDisabledSpeedTiers}
        onFindDevices={onFindDevices}
        onInvalidModalClose={onInvalidModalClose}
        onLoadAddressSuggestions={onLoadAddressSuggestions}
        onTempLocationTypeChange={onTempLocationTypeChange}
        onTempLocationTypeReset={onTempLocationTypeReset}
        onSearch={onSearch}
        onZipcodeChange={onZipcodeChange}
        onDeviceInfo={onDeviceInfo}
        zipcodeAutofill={zipcodeAutofill}
        zipcodeSpeedTiers={zipcodeSpeedTiers}
      />
      <div className={disclaimerCard}>
        <Exclamation className={icon} />
        <h1 >The service address requires current Xfinity equipment</h1>
        <div className={content}>
          <p>In order to keep current Xfinity internet speed, Xfinity equipment is required. Visit our FAQ&rsquo;s for  more information on our available services. </p>
        </div>
        <div className={buttonGroup}>
          <a className={`${button} ${primary}`} href="https://www.xfinity.com/xclass-support" rel="noopener">Visit FAQ&rsquo;s</a>
        </div>
      </div>
    </>
  );
};

FdxDisclaimer.propTypes = {
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func.isRequired,
  onDeviceInfo: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onFindDevices: PropTypes.func.isRequired,
  onInvalidModalClose: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onTempLocationTypeReset: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};

