import {authenticate, AUTH} from 'src/shared/auth/actions';
import {loadUser} from 'src/shared/user/actions';
import {startDataLayerTracking} from 'src/shared/dataLayer/actions';
import {
  waitUntil,
  waitUntilOneOf,
} from 'src/shared/events';
import {changeView} from 'src/shared/currentView/actions';
import {query} from 'src/shared/params/selectors';
import {isFullyAuthenticated} from 'src/shared/auth/selectors';
import {pushHistory} from 'src/shared/history/actions';
import {fragment} from 'src/pages/scheduleCallback/utils';
import {LOADING_VIEW, UNAUTHENTICATED_VIEW, EPON_ADDRESS, FDX_DISCLAIMER} from 'src/pages/deviceInfo/constants/views';
import {
  loadDeviceAutofill,
  loadFiltersDropdown,
  loadAddressByEloc,
  LOAD_FILTERS_DROPDOWN_COMPLETE,
  LOAD_ELOC_ID_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/deviceSearch';
import {
  loadZipcodeAutofill,
  setAddress,
  setZipcode,
  setLocationType,
  setTempLocation,
  findDeviceFragment,
  getAddressSpeed,
  LOAD_ADDRESS_SPEED_TIERS_COMPLETE,
  LOAD_ADDRESS_VALID_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/findDevices';
import {clearAllDevicesForComparison} from 'src/pages/deviceInfo/store/actions/compareDevices';
import {setDisabledSpeedTiers} from 'src/pages/deviceInfo/store/actions/setDisabledSpeedTiers';
import {setVoiceEnabled} from 'src/pages/deviceInfo/store/actions/setVoiceEnabled';
import {
  accountAddress,
  accountSpeedTier,
  accountZipcode,
  yourEquipment,
  addressValid,
  isSpeedtierFdxActive,
} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {setYourDevice} from './setYourDevice';
import {GET_MDI_SESSION_COMPLETE} from 'src/pages/deviceInfo/store/actions/getMDISession';
import {
  GET_MDI_USER_SESSION_COMPLETE,
  readMDIUserAccountDevices,
  readMDIUserAccountProfile,
  readMDIUserAccountSpeedtier,
  READ_MDI_USER_ACCOUNT_PROFILE_COMPLETE,
  READ_MDI_USER_ACCOUNT_DEVICES_COMPLETE,
  READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE,
} from './readMDIUserAccount';
import {maintainMDISession} from './maintainMDISession';
import {flags} from 'src/shared/config/selectors';
import {redirect} from 'src/shared/redirect/actions';
import {home} from 'src/shared/pagePaths/index.js';
import {
  setAuthFragment,
  setPageFragment,
} from 'src/pages/deviceInfo/store/actions/setPageFragment';
import {
  setDefaultDownloadSpeed,
  setDownloadSpeed,
} from 'src/pages/deviceInfo/store/actions/setDownloadSpeed';
import {setClearAll} from 'src/pages/deviceInfo/store/actions/setClearAll';

export const startFlow = () => async (dispatch, getState) => {
  if (!flags(getState()).deviceInfoEnabled) {
    return dispatch(redirect(home()));
  }
  dispatch(authenticate());
  dispatch(loadUser());
  dispatch(startDataLayerTracking());

  await waitUntil(AUTH);

  dispatch(maintainMDISession());

  await waitUntilOneOf(GET_MDI_SESSION_COMPLETE, GET_MDI_USER_SESSION_COMPLETE);

  dispatch(loadDeviceAutofill());
  dispatch(loadZipcodeAutofill());
  dispatch(loadFiltersDropdown());

  const paramLocationId = query(getState()).locationId;
  const paramAddress = query(getState()).address;
  const paramServiceZip = paramAddress?.slice(-5);
  const paramSpeed = parseInt(query(getState()).speed, 10);
  const paramVoice = query(getState()).voice === 'true';

  if (paramLocationId) {
    dispatch(loadAddressByEloc(paramLocationId));
    await waitUntil(LOAD_ELOC_ID_COMPLETE);
  }

  const fullStreetAddress = getState()?.elocAddress?.fullStreetAddress || paramAddress;
  if (isFullyAuthenticated(getState())) {
    await dispatch(readMDIUserAccountProfile());

    dispatch(readMDIUserAccountDevices());
    dispatch(readMDIUserAccountSpeedtier());
    await waitUntil([
      READ_MDI_USER_ACCOUNT_PROFILE_COMPLETE,
      READ_MDI_USER_ACCOUNT_DEVICES_COMPLETE,
      READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE,
    ]);

    dispatch(setAddress(accountAddress(getState())));
    dispatch(getAddressSpeed(fullStreetAddress));
    await waitUntil(LOAD_ADDRESS_VALID_COMPLETE);

    if (addressValid(getState()).fdxActive || isSpeedtierFdxActive(getState())) {
      dispatch(changeView(FDX_DISCLAIMER));
      return;
    }

    if (fullStreetAddress && addressValid(getState()).validAddress) {
      dispatch(setAddress(fullStreetAddress));
    }
    if (getState().MDIUserAccountProfile.serviceAddress.dropType === 'EPON') {
      dispatch(changeView(EPON_ADDRESS));
      return;
    }
    dispatch(setZipcode(accountZipcode(getState())));
    dispatch(setDefaultDownloadSpeed(paramSpeed || accountSpeedTier(getState())));
    dispatch(setDownloadSpeed(paramSpeed || accountSpeedTier(getState())));
    dispatch(setYourDevice(yourEquipment(getState())[0] || {}));
    await waitUntil(LOAD_FILTERS_DROPDOWN_COMPLETE);
    return dispatch(setAuthFragment('auth'));
  }

  if (fullStreetAddress && paramSpeed) {
    dispatch(setPageFragment('landing'));
    dispatch(changeView(LOADING_VIEW));
    dispatch(clearAllDevicesForComparison());
    dispatch(setAddress(fullStreetAddress));
    paramServiceZip && dispatch(setZipcode(paramServiceZip));
    dispatch(setLocationType('address'));
    dispatch(setTempLocation(fullStreetAddress));
    dispatch(setDisabledSpeedTiers(true));
    dispatch(setVoiceEnabled(paramVoice));
    dispatch(setDefaultDownloadSpeed(paramSpeed));
    dispatch(setDownloadSpeed(paramSpeed));
    dispatch(getAddressSpeed(fullStreetAddress));
    await waitUntil(LOAD_ADDRESS_VALID_COMPLETE);

    if (addressValid(getState()).fdxActive) {
      dispatch(changeView(FDX_DISCLAIMER));
      return;
    }

    if (addressValid(getState()).validAddress) {
      await waitUntil(LOAD_ADDRESS_SPEED_TIERS_COMPLETE);
      await waitUntil(LOAD_FILTERS_DROPDOWN_COMPLETE);
      return dispatch(findDeviceFragment('results', paramSpeed, fullStreetAddress));
    }
    dispatch(setClearAll());
  }
  dispatch(pushHistory(changeView(UNAUTHENTICATED_VIEW), fragment('unauth')));
  dispatch(setPageFragment('unauth'));
  return dispatch(changeView(UNAUTHENTICATED_VIEW));
};
