import React from 'react';
import PropTypes from 'prop-types';
import {Unauthenticated} from '../Unauthenticated';
import {ResultsView} from '../ResultsView';
import {DetailsView} from '../DetailsView';
import {ComparisonView} from '../ComparisonView';
import {LoadingView} from '../LoadingView';
import {EponErrorMessage} from '../EponErrorMessage';
import {
  AUTHENTICATED_VIEW,
  COMPARISON_VIEW,
  DETAILS_VIEW,
  LOADING_VIEW,
  RESULTS_VIEW,
  UNAUTHENTICATED_VIEW,
  EPON_ADDRESS,
  FDX_DISCLAIMER,
} from 'src/pages/deviceInfo/constants/views';
import {FdxDisclaimer} from '../FdxDisclaimer';

export const DeviceInfoPage = ({
  account,
  accountSpeedTier,
  addressSpeedTiers,
  addressSuggestions,
  comparedDevices,
  currentView,
  deviceDetails,
  deviceFilters,
  deviceResults,
  devicesForComparison,
  disabledSpeedTiers,
  downloadSpeedTiers,
  filtersDropdown,
  isLoadingDevices,
  isLoadingSpeedTiers,
  sortKey,
  keyWord,
  location,
  locationErrorMessage,
  onAddressChange,
  onBrandChange,
  onBuiltInWifiChange,
  onClearAddressSuggestions,
  onClearAll,
  onClearAllDevice,
  onCompare,
  onDetails,
  onDeviceInfo,
  onDisabledSpeedTiers,
  onDocsisChannelsChange,
  onDocsisVersionChange,
  onDownloadSpeedChange,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onTempLocationTypeReset,
  onSearch,
  onSelectToCompare,
  onShowUnsupportedDevicesChange,
  onSortKeyChange,
  onVoiceEnabledChange,
  onYourDevice,
  onZipcodeChange,
  signIn,
  voiceStatus,
  yourDevice,
  yourEquipment,
  zipcodeAutofill,
  zipcodeSpeedTiers,
}) => (
  <main>
    {(() => {
      switch (currentView) {
        case EPON_ADDRESS:
          return (
            <EponErrorMessage
              accountSpeedTier={accountSpeedTier}
              addressSpeedTiers={addressSpeedTiers}
              addressSuggestions={addressSuggestions}
              disabledSpeedTiers={disabledSpeedTiers}
              isLoadingSpeedTiers={isLoadingSpeedTiers}
              location={location}
              locationErrorMessage={locationErrorMessage}
              onAddressChange={onAddressChange}
              onClearAddressSuggestions={onClearAddressSuggestions}
              onDisabledSpeedTiers={onDisabledSpeedTiers}
              onFindDevices={onFindDevices}
              onInvalidModalClose={onInvalidModalClose}
              onLoadAddressSuggestions={onLoadAddressSuggestions}
              onTempLocationTypeChange={onTempLocationTypeChange}
              onTempLocationTypeReset={onTempLocationTypeReset}
              onSearch={onSearch}
              onZipcodeChange={onZipcodeChange}
              onDeviceInfo={onDeviceInfo}
              zipcodeAutofill={zipcodeAutofill}
              zipcodeSpeedTiers={zipcodeSpeedTiers}
            />);
        case AUTHENTICATED_VIEW:
        case RESULTS_VIEW:
          return (
            <ResultsView
              account={account}
              accountSpeedTier={accountSpeedTier}
              addressSpeedTiers={addressSpeedTiers}
              addressSuggestions={addressSuggestions}
              deviceFilters={deviceFilters}
              deviceResults={deviceResults}
              devicesForComparison={devicesForComparison}
              disabledSpeedTiers={disabledSpeedTiers}
              downloadSpeedTiers={downloadSpeedTiers}
              filtersDropdown={filtersDropdown}
              isLoadingDevices={isLoadingDevices}
              isLoadingSpeedTiers={isLoadingSpeedTiers}
              keyWord={keyWord}
              location={location}
              locationErrorMessage={locationErrorMessage}
              onAddressChange={onAddressChange}
              onBrandChange={onBrandChange}
              onBuiltInWifiChange={onBuiltInWifiChange}
              onClearAddressSuggestions={onClearAddressSuggestions}
              onClearAll={onClearAll}
              onClearAllDevice={onClearAllDevice}
              onCompare={onCompare}
              onDetails={onDetails}
              onDisabledSpeedTiers={onDisabledSpeedTiers}
              onDocsisChannelsChange={onDocsisChannelsChange}
              onDocsisVersionChange={onDocsisVersionChange}
              onDownloadSpeedChange={onDownloadSpeedChange}
              onFindDevices={onFindDevices}
              onInvalidModalClose={onInvalidModalClose}
              onLoadAddressSuggestions={onLoadAddressSuggestions}
              onTempLocationTypeChange={onTempLocationTypeChange}
              onTempLocationTypeReset={onTempLocationTypeReset}
              onSearch={onSearch}
              onSelectToCompare={onSelectToCompare}
              onShowUnsupportedDevicesChange={onShowUnsupportedDevicesChange}
              onSortKeyChange={onSortKeyChange}
              onVoiceEnabledChange={onVoiceEnabledChange}
              onYourDevice={onYourDevice}
              onZipcodeChange={onZipcodeChange}
              onDeviceInfo={onDeviceInfo}
              sortKey={sortKey}
              voiceStatus={voiceStatus}
              yourDevice={yourDevice}
              yourEquipment={yourEquipment}
              zipcodeAutofill={zipcodeAutofill}
              zipcodeSpeedTiers={zipcodeSpeedTiers}
            />
          );
        case UNAUTHENTICATED_VIEW:
          return (
            <Unauthenticated
              addressSpeedTiers={addressSpeedTiers}
              addressSuggestions={addressSuggestions}
              disabledSpeedTiers={disabledSpeedTiers}
              isLoadingSpeedTiers={isLoadingSpeedTiers}
              location={location}
              locationErrorMessage={locationErrorMessage}
              onAddressChange={onAddressChange}
              onClearAddressSuggestions={onClearAddressSuggestions}
              onDisabledSpeedTiers={onDisabledSpeedTiers}
              onFindDevices={onFindDevices}
              onInvalidModalClose={onInvalidModalClose}
              onLoadAddressSuggestions={onLoadAddressSuggestions}
              onTempLocationTypeChange={onTempLocationTypeChange}
              onSearch={onSearch}
              onZipcodeChange={onZipcodeChange}
              onDeviceInfo={onDeviceInfo}
              signIn={signIn}
              zipcodeAutofill={zipcodeAutofill}
              zipcodeSpeedTiers={zipcodeSpeedTiers}
            />
          );
        case DETAILS_VIEW:
          return (
            <DetailsView
              accountSpeedTier={accountSpeedTier}
              addressSpeedTiers={addressSpeedTiers}
              addressSuggestions={addressSuggestions}
              deviceDetails={deviceDetails}
              disabledSpeedTiers={disabledSpeedTiers}
              isLoadingSpeedTiers={isLoadingSpeedTiers}
              location={location}
              locationErrorMessage={locationErrorMessage}
              onAddressChange={onAddressChange}
              onClearAddressSuggestions={onClearAddressSuggestions}
              onClearAll={onClearAll}
              onDeviceInfo={onDeviceInfo}
              onDisabledSpeedTiers={onDisabledSpeedTiers}
              onFindDevices={onFindDevices}
              onInvalidModalClose={onInvalidModalClose}
              onLoadAddressSuggestions={onLoadAddressSuggestions}
              onTempLocationTypeChange={onTempLocationTypeChange}
              onTempLocationTypeReset={onTempLocationTypeReset}
              onSearch={onSearch}
              onZipcodeChange={onZipcodeChange}
              zipcodeAutofill={zipcodeAutofill}
              zipcodeSpeedTiers={zipcodeSpeedTiers}
            />
          );
        case COMPARISON_VIEW:
          return (
            <ComparisonView
              comparedDevices={comparedDevices}
            />
          );
        case FDX_DISCLAIMER:
          return (
            <FdxDisclaimer
              account={account}
              accountSpeedTier={accountSpeedTier}
              addressSpeedTiers={addressSpeedTiers}
              addressSuggestions={addressSuggestions}
              deviceFilters={deviceFilters}
              deviceResults={deviceResults}
              devicesForComparison={devicesForComparison}
              disabledSpeedTiers={disabledSpeedTiers}
              downloadSpeedTiers={downloadSpeedTiers}
              filtersDropdown={filtersDropdown}
              isLoadingDevices={isLoadingDevices}
              isLoadingSpeedTiers={isLoadingSpeedTiers}
              keyWord={keyWord}
              location={location}
              locationErrorMessage={locationErrorMessage}
              onAddressChange={onAddressChange}
              onBrandChange={onBrandChange}
              onBuiltInWifiChange={onBuiltInWifiChange}
              onClearAddressSuggestions={onClearAddressSuggestions}
              onClearAll={onClearAll}
              onClearAllDevice={onClearAllDevice}
              onCompare={onCompare}
              onDetails={onDetails}
              onDisabledSpeedTiers={onDisabledSpeedTiers}
              onDocsisChannelsChange={onDocsisChannelsChange}
              onDocsisVersionChange={onDocsisVersionChange}
              onDownloadSpeedChange={onDownloadSpeedChange}
              onFindDevices={onFindDevices}
              onInvalidModalClose={onInvalidModalClose}
              onLoadAddressSuggestions={onLoadAddressSuggestions}
              onTempLocationTypeChange={onTempLocationTypeChange}
              onTempLocationTypeReset={onTempLocationTypeReset}
              onSearch={onSearch}
              onSelectToCompare={onSelectToCompare}
              onShowUnsupportedDevicesChange={onShowUnsupportedDevicesChange}
              onSortKeyChange={onSortKeyChange}
              onVoiceEnabledChange={onVoiceEnabledChange}
              onYourDevice={onYourDevice}
              onZipcodeChange={onZipcodeChange}
              onDeviceInfo={onDeviceInfo}
              sortKey={sortKey}
              voiceStatus={voiceStatus}
              yourDevice={yourDevice}
              yourEquipment={yourEquipment}
              zipcodeAutofill={zipcodeAutofill}
              zipcodeSpeedTiers={zipcodeSpeedTiers}
            />
          );
        case LOADING_VIEW:
        default:
          return (<LoadingView />);
      }
    })()}
  </main>
);

DeviceInfoPage.propTypes = {
  account: PropTypes.object,
  accountSpeedTier: PropTypes.number,
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  comparedDevices: PropTypes.array,
  currentView: PropTypes.string.isRequired,
  deviceDetails: PropTypes.array,
  deviceFilters: PropTypes.object,
  deviceResults: PropTypes.array,
  devicesForComparison: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  downloadSpeedTiers: PropTypes.array,
  filtersDropdown: PropTypes.object,
  isLoadingDevices: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  keyWord: PropTypes.string,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onBrandChange: PropTypes.func.isRequired,
  onBuiltInWifiChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onClearAllDevice: PropTypes.func.isRequired,
  onCompare: PropTypes.func.isRequired,
  onDetails: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onDocsisChannelsChange: PropTypes.func.isRequired,
  onDocsisVersionChange: PropTypes.func.isRequired,
  onDownloadSpeedChange: PropTypes.func.isRequired,
  onFindDevices: PropTypes.func.isRequired,
  onInvalidModalClose: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onTempLocationTypeReset: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onSelectToCompare: PropTypes.func.isRequired,
  onShowUnsupportedDevicesChange: PropTypes.func.isRequired,
  onSortKeyChange: PropTypes.func.isRequired,
  onVoiceEnabledChange: PropTypes.func.isRequired,
  onYourDevice: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  onDeviceInfo: PropTypes.func.isRequired,
  signIn: PropTypes.string.isRequired,
  sortKey: PropTypes.string,
  voiceStatus: PropTypes.string,
  yourDevice: PropTypes.object,
  yourEquipment: PropTypes.array,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};
