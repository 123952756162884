import React, {useState, useRef, useEffect} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  closeBtn,
  closeIcon,
  dlspeed,
  smallIcon,
  tooltip,
  tooltipBubble,
  tooltipMessage,
  tooltipRight,
  tooltipTrigger,
} from './styles.module.scss';
import CloseIcon from 'src/svgs/action/close.svg';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import InfoIcon from 'src/svgs/mdi/icon_info.svg';
import SpeedAlertIcon from 'src/svgs/mdi/speedAlert.svg';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';

const KEYCODE_TAB = 9;
const ESC = 27;

export const Tooltip = ({
  children,
  iconType,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeRef = useRef();
  const buttonRef = useRef();
  const ref = useRef();

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    buttonRef.current.focus();
    setIsOpen(false);
  };

  const useOnClickOutside = (ref, handler) => {
    useEffect(
      () => {
        /* istanbul ignore next */
        const listener = event => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler();
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      [ref, handler]
    );
  };

  useOnClickOutside(ref, onClose);

  useEffect(() => {
    if (isOpen) {
      closeRef.current.focus();
      const listener = e => {
        if (e.keyCode === ESC) {
          return onClose();
        }
        const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
        if (!isTabPressed) {
          return;
        }
        closeRef.current.focus();
        e.preventDefault();
      };
      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  });

  return (
    <>
      <div
        className={tooltip}
      >
        {
          isOpen && (
            <div
              className={classnames(tooltipBubble, tooltipRight, {[dlspeed]: iconType === 'exclamation'})}
            >
              <div
                ref={ref}
                className={tooltipMessage}
                role="dialog"
                aria-labelledby="dialogTitle"
              >
                <button
                  className={`${closeBtn} ${linkButton}`}
                  onClick={onClose}
                  ref={closeRef}
                >
                  <CloseIcon className={closeIcon} />
                  <span className={a11yHidden}>Close Tooltip</span>
                </button>
                <h3 id="dialogTitle">{title}</h3>
                {children}
              </div>
            </div>
          )
        }
      </div>
      <button
        className={`${tooltipTrigger} ${linkButton}`}
        onClick={onOpen}
        type="button"
        ref={buttonRef}
      >
        {iconType === 'exclamation' ? <SpeedAlertIcon className={smallIcon} /> : <InfoIcon />}
        <span className={a11yHidden}>{iconType ? 'download speed Warning' : title} Tooltip</span>
      </button>
    </>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  iconType: PropTypes.string,
  children: PropTypes.node.isRequired,
};
