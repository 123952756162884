import React from 'react';
import {
  wrap,
  section as sectionStyle,
  content,
  footNote,
} from './styles.module.scss';
import PromoImg from 'src/img/mdi/mdi_collage.png';

export const Promo = () => (
  <section className={sectionStyle}>
    <div className={wrap}>
      <div className={content}>
        <div>
          <h2>Not sure about buying?</h2>
          <p>Consider the benefits of renting from us, such as the added protection of regular security updates and guest access to Xfinity WIFI hotspots* in your home without sharing your password.</p>
          <a href="https://www.xfinity.com/learn/internet-service/modems-and-routers" rel="noopener noreferrer" target="_blank">Learn more</a>
        </div>
        <div>
          <img src={PromoImg} alt="promoImg"></img>
        </div>
      </div>
      <div className={footNote}>
        <p>*Included in Internet plans of 25 Mbps or higher.</p>
      </div>
    </div>
  </section>
);
