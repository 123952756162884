import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import {
  headerMain,
  headerWrap,
  addressAccordion,
  addressAccordionTrigger,
  addressAccordionBody,
  enterLocation,
  logo,
} from './styles.module.scss';
import ExpandIcon from 'src/svgs/action/expand.svg';
import {Modal} from '../Modal';
import {FindDevice} from '../FindDevice';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import Xfinity from 'src/svgs/Xfinity.svg';
import {address} from 'src/pages/deviceInfo/constants/locationTypes';

export const DeviceSearchHeader = ({
  addressSpeedTiers,
  addressSuggestions,
  disabledSpeedTiers,
  isLoadingSpeedTiers,
  location = {},
  locationErrorMessage,
  onAddressChange,
  onClearAddressSuggestions,
  onDeviceInfo,
  onDisabledSpeedTiers,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onTempLocationTypeReset,
  onSearch,
  onZipcodeChange,
  zipcodeAutofill,
  zipcodeSpeedTiers,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [retainFocus, setRetainFocus] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [speedTiersLoaded, setSpeedTiersLoaded] = useState(false);
  const showAccordionBox = expanded || retainFocus;

  useEffect(() => {
    if (isLoadingSpeedTiers) {
      setSpeedTiersLoaded(true);
    }
  }, [isLoadingSpeedTiers]);

  /**
   * these next few lines make me want to throw up, but essentially
   * we want to close the modal when the user enters an epon address
   * the problem is we need to "wait" until after the second time they entire an address
   * (the first time is when they enter it on the devices screen, the second time is from
   * the search dropdown)
   *
   * So the first use effect waits until the speedtiers are loaded (after they enter
   * an address from the dropdown) and then checks to see if it is an epon address
   * if it is we force close the modal and then reset the force close flag
   */
  useEffect(() => {
    if (speedTiersLoaded && location.isEPONAddress) {
      setForceClose(true);
    }
  }, [location.isEPONAddress]);

  useEffect(() => {
    forceClose && setForceClose(false);
  }, [forceClose]);

  const handleMouseDown = () => {
    setRetainFocus(true);
  };
  const handleMouseUp = () => {
    setTimeout(() => setRetainFocus(false));
  };

  return (
    <div className={headerMain}>
      <div className={headerWrap}>
        <button className={linkButton} onClick={onDeviceInfo}>
          <Xfinity className={logo} />
        </button>
        <div>
          {
            location.locationType && ((location.address || location.zipcode)
              ? (
                <div className={addressAccordion}>
                  <button
                    className={addressAccordionTrigger}
                    aria-label="address"
                    aria-expanded={showAccordionBox}
                    onBlur={() => setExpanded(false)}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <span>{location.locationType === address ? truncate(location.address, {length: 32}) : location.zipcode}</span>
                    <ExpandIcon />
                  </button>
                  <ul
                    role="listbox"
                    className={addressAccordionBody}
                    aria-hidden={!showAccordionBox}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                  >
                    <Modal
                      expanded={expanded}
                      setExpanded={setExpanded}
                      forceClose={forceClose}
                      onTempLocationTypeReset={onTempLocationTypeReset}
                      title="Your location matters."
                      triggerText={`Look up a different ${location.locationType}`}
                    >
                      <div>
                        <p>Download speeds vary by location. Enter your {location.locationType} and select your desired download speed to get started.</p>
                        <FindDevice
                          addressSpeedTiers={addressSpeedTiers}
                          addressSuggestions={addressSuggestions}
                          disabledSpeedTiers={disabledSpeedTiers}
                          isLoadingSpeedTiers={isLoadingSpeedTiers}
                          location={location}
                          locationErrorMessage={locationErrorMessage}
                          onAddressChange={onAddressChange}
                          onClearAddressSuggestions={onClearAddressSuggestions}
                          onDisabledSpeedTiers={onDisabledSpeedTiers}
                          onFindDevices={onFindDevices}
                          onInvalidModalClose={onInvalidModalClose}
                          onLoadAddressSuggestions={onLoadAddressSuggestions}
                          onTempLocationTypeChange={onTempLocationTypeChange}
                          onSearch={onSearch}
                          onZipcodeChange={onZipcodeChange}
                          zipcodeAutofill={zipcodeAutofill}
                          zipcodeSpeedTiers={zipcodeSpeedTiers}
                        />
                      </div>
                    </Modal>
                  </ul>
                </div>
              )
              : (
                <div className={enterLocation}>
                  <Modal
                    forceClose={forceClose}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    onTempLocationTypeReset={onTempLocationTypeReset}
                    title="Your location matters."
                    triggerText={`Enter your ${location.locationType}`}
                  >
                    <div>
                      <p>Download speeds vary by location. Enter your {location.locationType} and select your desired download speed to get started.</p>
                      <FindDevice
                        addressSpeedTiers={addressSpeedTiers}
                        addressSuggestions={addressSuggestions}
                        disabledSpeedTiers={disabledSpeedTiers}
                        isLoadingSpeedTiers={isLoadingSpeedTiers}
                        location={location}
                        locationErrorMessage={locationErrorMessage}
                        onAddressChange={onAddressChange}
                        onClearAddressSuggestions={onClearAddressSuggestions}
                        onDisabledSpeedTiers={onDisabledSpeedTiers}
                        onFindDevices={onFindDevices}
                        onInvalidModalClose={onInvalidModalClose}
                        onLoadAddressSuggestions={onLoadAddressSuggestions}
                        onTempLocationTypeChange={onTempLocationTypeChange}
                        onSearch={onSearch}
                        onZipcodeChange={onZipcodeChange}
                        zipcodeAutofill={zipcodeAutofill}
                        zipcodeSpeedTiers={zipcodeSpeedTiers}
                      />
                    </div>
                  </Modal>
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

DeviceSearchHeader.propTypes = {
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func,
  onClearAddressSuggestions: PropTypes.func,
  onDeviceInfo: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func,
  onFindDevices: PropTypes.func,
  onInvalidModalClose: PropTypes.func,
  onLoadAddressSuggestions: PropTypes.func,
  onTempLocationTypeChange: PropTypes.func,
  onTempLocationTypeReset: PropTypes.func,
  onSearch: PropTypes.func,
  onZipcodeChange: PropTypes.func,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};
