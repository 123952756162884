import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DeviceSearchHeader} from '../DeviceSearchHeader';
import {
  backIcon,
  containerDeviceTitle,
  containerDevice,
  deviceHeader,
  deviceTableHeader,
  deviceTableBody,
  deviceTableLeftBody,
  deviceTableRightBody,
  deviceTableTr,
  deviceNotice,
  warningIcon,
  notes,
  showOnMobile,
} from './styles.module.scss';
import BackIcon from 'src/svgs/mdi/backIcon.svg';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import WarningIcon from 'src/svgs/mdi/warning.svg';
import {Tooltip} from '../Tooltip';
import isEmpty from 'lodash/isEmpty';
import DefaultImg from 'src/img/mdi/internet_generic_hd.png';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';
import {displaySpeed} from 'src/pages/deviceInfo/utils';

export const DetailsView = ({
  accountSpeedTier,
  addressSpeedTiers,
  addressSuggestions,
  deviceDetails,
  disabledSpeedTiers,
  isLoadingSpeedTiers,
  location,
  locationErrorMessage,
  onAddressChange,
  onClearAddressSuggestions,
  onClearAll,
  onDeviceInfo,
  onDisabledSpeedTiers,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onTempLocationTypeReset,
  onSearch,
  onZipcodeChange,
  zipcodeAutofill,
  zipcodeSpeedTiers,
}) => {
  const backRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('h1').focus();
  }, []);
  const incompatibleSpeed = (accountTier, speed) => speed >= accountTier;

  return (
    <>
      <DeviceSearchHeader
        addressSpeedTiers={addressSpeedTiers}
        addressSuggestions={addressSuggestions}
        disabledSpeedTiers={disabledSpeedTiers}
        isLoadingSpeedTiers={isLoadingSpeedTiers}
        location={location}
        locationErrorMessage={locationErrorMessage}
        onAddressChange={onAddressChange}
        onClearAddressSuggestions={onClearAddressSuggestions}
        onDisabledSpeedTiers={onDisabledSpeedTiers}
        onFindDevices={onFindDevices}
        onInvalidModalClose={onInvalidModalClose}
        onLoadAddressSuggestions={onLoadAddressSuggestions}
        onTempLocationTypeChange={onTempLocationTypeChange}
        onTempLocationTypeReset={onTempLocationTypeReset}
        onSearch={onSearch}
        onZipcodeChange={onZipcodeChange}
        onDeviceInfo={onDeviceInfo}
        zipcodeAutofill={zipcodeAutofill}
        zipcodeSpeedTiers={zipcodeSpeedTiers}
      />
      {
        !isEmpty(deviceDetails) && (
          <>
            <div className={containerDeviceTitle}>
              <div>
                <div>
                  <button
                    value="goBackBtn1"
                    className={`${linkButton} ${backIcon}`}
                    onClick={() => window.history.back()}
                    ref={backRef}
                    aria-labelledby="backOnDetailView"
                  >
                    <span
                      className={a11yHidden}
                      id="backOnDetailView"
                    >
                    Back
                    </span>
                    <BackIcon />
                  </button>
                  <div>All Devices</div>
                </div>
                <div>{deviceDetails[0].productName}</div>
              </div>
            </div>
            <div className={containerDevice}>
              <div className={deviceHeader}>
                <h1 tabIndex="-1">{deviceDetails[0].productName}</h1>
                <p>{`${deviceDetails[0].vendorName} ${deviceDetails[0].baseModel} | Model number: ${deviceDetails[0].baseModel}`}</p>
              </div>
              <div className={deviceTableHeader}>
                <span>Model Specifications</span>
              </div>
              <div className={deviceTableBody}>
                <div className={deviceTableLeftBody}>
                  {
                    deviceDetails[0].eolStatus === 1 && (
                      <WarningIcon className={warningIcon} />
                    )
                  }
                  <img src={deviceDetails[0].image ? deviceDetails[0].image : DefaultImg} alt="deviceImg" />
                  {
                    deviceDetails[0].eolStatus === 1 && (
                      <>
                        <p>This device is not supported.</p>
                        <button
                          className={linkButton}
                          onClick={() => {
                            window.history.back();
                            onClearAll();
                          }}
                          value="goBackBtn2"
                        >
                        See compatible equipment
                        </button>
                      </>
                    )
                  }
                  {
                    incompatibleSpeed(
                      parseInt(accountSpeedTier, 10),
                      parseInt(deviceDetails[0].dsSpeed.toString().slice(0, -6), 10)
                    )
                    && (
                      <p>This device is compatible with your service download speed of {accountSpeedTier} Mbps.</p>
                    )
                  }
                </div>
                <div className={deviceTableRightBody}>
                  <div className={deviceTableTr}>
                    <div>Device Type</div>
                    <div>{deviceDetails[0].deviceType}</div>
                  </div>
                  <div className={deviceTableTr}>
                    <div>Wired Download Speed
                      <Tooltip title="Wired Download Speed">
                        <p>Your wired download speed refers to how fast your modem pulls data from the Internet via wired connection. Once you know your plan’s download speed*, choose a device that can accommodate that speed. Keep in mind, devices compatible with speeds higher than your current plan are fine, but devices geared to speeds lower than your plan will result in poor performance.</p>
                        <p>*Actual plan speeds vary and are not guaranteed.</p>
                      </Tooltip>
                    </div>
                    <div>
                      {deviceDetails[0] && `Up to ${displaySpeed(Math.floor(deviceDetails[0]?.dsSpeed) / 1e6)}`}
                    </div>
                  </div>
                  {
                    deviceDetails[0].wifi === 1 && (
                      <>
                        <div className={deviceTableTr}>
                          <div>WiFi (2.4 GHz) Download Speed
                            <Tooltip title="WiFi Download Speed">
                              <p>Your WiFi download speed refers to how fast your modem delivers data wirelessly from the Internet to your WiFi enabled client devices. Once you know your plan’s download speed*, choose a device that can accommodate that speed. Keep in mind, devices compatible with speeds higher than your current plan are fine, but devices geared to speeds lower than your plan will result in poor performance.</p>
                              <p>*Actual plan speeds vary and are not guaranteed.</p>
                            </Tooltip>
                          </div>
                          <div>
                            {deviceDetails[0].dsSpeedWifi24 === null ? 'WiFi data is not available for this device'
                              : deviceDetails[0].dsSpeedWifi24 >= 10e8
                                ? `Up to ${deviceDetails[0].dsSpeedWifi24.toString().slice(0, -9)} Gbps`
                                : `Up to ${deviceDetails[0].dsSpeedWifi24.toString().slice(0, -6)} Mbps`}
                          </div>
                        </div>
                        <div className={deviceTableTr}>
                          <div>WiFi (5 GHz) Download Speed
                            <Tooltip title="WiFi Download Speed">
                              <p>Your WiFi download speed refers to how fast your modem delivers data wirelessly from the Internet to your WiFi enabled client devices. Once you know your plan’s download speed*, choose a device that can accommodate that speed. Keep in mind, devices compatible with speeds higher than your current plan are fine, but devices geared to speeds lower than your plan will result in poor performance.</p>
                              <p>*Actual plan speeds vary and are not guaranteed.</p>
                            </Tooltip>
                          </div>
                          <div>
                            {deviceDetails[0].dsSpeedWifi50 === null ? 'WiFi data is not available for this device'
                              : deviceDetails[0].dsSpeedWifi50 >= 10e8
                                ? `Up to ${deviceDetails[0].dsSpeedWifi50.toString().slice(0, -9)} Gbps`
                                : `Up to ${deviceDetails[0].dsSpeedWifi50.toString().slice(0, -6)} Mbps`}
                          </div>
                        </div>
                      </>
                    )
                  }
                  <div className={deviceTableTr}>
                    <div>Voice/Telephone enabled</div>
                    <div>{deviceDetails[0].voice === 0 ? 'No' : 'Yes'}</div>
                  </div>
                  <div className={deviceTableTr}>
                    <div>Built-in WiFi</div>
                    <div>{deviceDetails[0].wifi === 0 ? 'No' : 'Yes'}</div>
                  </div>
                  <div className={deviceTableTr}>
                    <div>DOCSIS Channels
                      <Tooltip title="DOCSIS Channels">
                        <p>DOCSIS downstream and upstream channels determine the maximum wired ethernet download speed and upload speed a specific device will allow. Choose a device with DOCSIS channels that work with your Internet plan’s download speed. By default, all Comcast-certified devices on this site will support all Comcast Internet plan upload speeds. Keep in mind that selecting a device with more downstream channels than your plan requires won’t increase your download speed.</p>
                      </Tooltip>
                    </div>
                    <div>{deviceDetails[0].docsisChannel}</div>
                  </div>
                  <div className={deviceTableTr}>
                    <div>DOCSIS Version
                      <Tooltip title="DOCSIS">
                        <p>DOCSIS is the acronym for Data Over Cable Service Internet Specification. It’s the international standard used for hardware that provides Internet service over cable TV.</p>
                      </Tooltip>
                    </div>
                    <div>{`${deviceDetails[0].docsisVersion.slice(0, 6)} ${deviceDetails[0].docsisVersion.slice(6)}`}</div>
                  </div>
                  <div className={deviceTableTr}>
                    <div>IPv6</div>
                    <div>{deviceDetails[0].ipv6 === 0 ? 'No' : 'Yes'}</div>
                  </div>
                  {
                    deviceDetails[0].notes && (
                      <div className={`${deviceTableTr} ${notes}`}>
                        <div>Notes</div>
                        <div>{deviceDetails[0].notes}</div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className={deviceNotice}>
                <p>The products on this site have been certified by CableLabs, Underwriters Laboratory (&quot;UL&quot;), Federal Communications Commission (FCC) and (if applicable) the WiFi Alliance. In addition, we test DOCSIS and IPv4/6 to confirm successful connections to our network. Depending on the product, we may have also tested other features (such as Xfinity Voice).</p>
                {
                  deviceDetails[0].wifi === 1 && (
                    <p>WiFi Download Speeds based upon third party testing; It’s important to note that speeds can be affected by a number of factors including your type of device, connection type, the number of devices in use, the age of your device, site traffic, content provider server capacity, and other internal network factors.</p>
                  )
                }
              </div>
            </div>
          </>
        )
      }
      <button
        className={`${linkButton} ${showOnMobile} ${backIcon}`}
        onClick={() => window.history.back()}
      >
        <BackIcon />
      </button>
    </>
  );
};

DetailsView.propTypes = {
  accountSpeedTier: PropTypes.number,
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  deviceDetails: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onDeviceInfo: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onFindDevices: PropTypes.func.isRequired,
  onInvalidModalClose: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onTempLocationTypeReset: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};
