import {createSelector} from 'reselect';
import {currentView} from 'src/shared/currentView/selectors';
import {
  AUTHENTICATED_VIEW,
  COMPARISON_VIEW,
  DETAILS_VIEW,
  EPON_ADDRESS,
  LOADING_VIEW,
  RESULTS_VIEW,
  UNAUTHENTICATED_VIEW,
  FDX_DISCLAIMER,
} from 'src/pages/deviceInfo/constants/views';
import {
  comparedDevices,
  deviceDetails,
} from 'src/pages/deviceInfo/store/selectors/deviceInfo';

export const screenName = createSelector(
  [currentView, comparedDevices, deviceDetails],
  (currentView, comparedDevices, deviceDetails) => {
    switch (currentView) {
      case LOADING_VIEW:
        return 'loading';
      case UNAUTHENTICATED_VIEW:
        return 'unauth home';
      case RESULTS_VIEW:
        return 'unauth results';
      case AUTHENTICATED_VIEW:
        return 'auth results';
      case DETAILS_VIEW:
        return `device details|${deviceDetails[0].vendorName} ${deviceDetails[0].baseModel}`;
      case COMPARISON_VIEW:
        return `compare|${comparedDevices[0].vendorName} ${comparedDevices[0].baseModel} x ${comparedDevices[1].vendorName} ${comparedDevices[1].baseModel}`;
      case EPON_ADDRESS:
        return 'epon address';
      case FDX_DISCLAIMER:
        return 'fdx address';
      default:
        return 'loading';
    }
  }
);
