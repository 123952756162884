import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  expandable,
  expandableContainer,
  focused,
  expandableTitle,
  expandableExpander,
  accordionBody,
} from './styles.module.scss';
import ExpandIcon from 'src/svgs/action/expand.svg';
import {Tooltip} from '../Tooltip';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';

export const Accordion = ({
  hasTooltip,
  id,
  title,
  tooltipTitle,
  tooltipDescription,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [focus, setFocus] = useState(false);
  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={expandable} id={id}>
      <div className={focus ? `${expandableContainer} ${focused}` : `${expandableContainer}`}>
        <div className={expandableTitle}>
          <button
            className={`${linkButton} buttonTitle`}
            onClick={toggle}
            aria-expanded={expanded}
            aria-label={title}
          >
            {title}
          </button>
          {hasTooltip === 'true'
            ?
            <Tooltip title={tooltipTitle}>
              <p>{tooltipDescription}</p>
            </Tooltip>
            : null}
        </div>
        <button
          onClick={toggle}
          className={expandableExpander}
          aria-expanded={expanded}
          aria-label={title}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        >
          <span className={a11yHidden}>{title}</span>
          <ExpandIcon />
        </button>
      </div>
      <div
        className={accordionBody}
        aria-hidden={!expanded}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  hasTooltip: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
  tooltipDescription: PropTypes.string,
};
