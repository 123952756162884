import {AUTH} from 'src/shared/auth/actions';
import {token} from 'src/shared/auth/selectors';
import {account} from 'src/shared/config/urls';
import {cspTokenParam} from 'src/shared/extendedProfile/selectors';
import {LOAD_USER_COMPLETE} from 'src/shared/user/actions';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {requiresAccountNumber} from 'src/shared/utils/resourcePrequisites';

export const READ_ACCOUNT_START = 'READ_ACCOUNT_START';
export const READ_ACCOUNT_COMPLETE = 'READ_ACCOUNT_COMPLETE';
export const READ_ACCOUNT_SUCCESS = 'READ_ACCOUNT_SUCCESS';
export const READ_ACCOUNT_ERROR = 'READ_ACCOUNT_ERROR';

export const readAccount = () => createFetchResourceAction({
  start: READ_ACCOUNT_START,
  success: READ_ACCOUNT_SUCCESS,
  error: READ_ACCOUNT_ERROR,
  complete: READ_ACCOUNT_COMPLETE,
  waitUntil: [AUTH, LOAD_USER_COMPLETE],
  qualify: requiresAccountNumber,
  url: state => account(state, cspTokenParam(state)),
  token,
  fetchOptions: {headers: {Client: 'dotcom_support'}},
});
