import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {StatefulHeader} from 'src/shared/components/StatefulHeader';
import {
  container,
  filterMobile,
  filterApplyMobile,
  customerinfoDiv,
  hiddenDiv,
} from './styles.module.scss';
import {button, primary} from 'src/styles/modules/button.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import {Comparison} from '../Comparison';
import {DeviceSearchHeader} from '../DeviceSearchHeader';
import {SortBy} from '../SortBy';
import {Filters} from '../Filters';
import {Listings} from '../Listings';
import {Message} from '../Message';

export const ResultsView = ({
  accountSpeedTier,
  addressSpeedTiers,
  addressSuggestions,
  deviceFilters,
  deviceResults,
  devicesForComparison,
  disabledSpeedTiers,
  downloadSpeedTiers,
  filtersDropdown,
  isLoadingDevices,
  isLoadingSpeedTiers,
  keyWord = '',
  location,
  locationErrorMessage,
  onAddressChange,
  onBrandChange,
  onBuiltInWifiChange,
  onClearAddressSuggestions,
  onClearAll,
  onClearAllDevice,
  onCompare,
  onDetails,
  onDeviceInfo,
  onDisabledSpeedTiers,
  onDocsisChannelsChange,
  onDocsisVersionChange,
  onDownloadSpeedChange,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onTempLocationTypeReset,
  onSearch,
  onSelectToCompare,
  onShowUnsupportedDevicesChange,
  onSortKeyChange,
  onVoiceEnabledChange,
  onZipcodeChange,
  onYourDevice,
  sortKey,
  voiceStatus,
  yourDevice,
  yourEquipment,
  zipcodeAutofill,
  zipcodeSpeedTiers,
}) => {
  const [showOnMobile, setShowOnMobile] = useState(false);
  const listingsRef = useRef();

  const onFocusFromComparison = id => {
    const checkbox = listingsRef.current.querySelector(`#device-${id}`);
    if (checkbox) {
      checkbox.focus();
    } else {
      setTimeout(() => {
        const checkbox = listingsRef.current.querySelector(`input[type="checkbox"]:not([disabled])`);
        if (checkbox) {
          checkbox.focus();
        }
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('h1').focus();
    // Add voice status check to enable voice/telephone enabled filter if the user is logged into a voice enabled account.
    if (voiceStatus === 'A') {
      onVoiceEnabledChange(true);
    }
  }, []);

  return (
    <>
      <div
        className={showOnMobile ? `${hiddenDiv}` : null}
      >
        <DeviceSearchHeader
          addressSpeedTiers={addressSpeedTiers}
          addressSuggestions={addressSuggestions}
          disabledSpeedTiers={disabledSpeedTiers}
          isLoadingSpeedTiers={isLoadingSpeedTiers}
          location={location}
          locationErrorMessage={locationErrorMessage}
          onAddressChange={onAddressChange}
          onClearAddressSuggestions={onClearAddressSuggestions}
          onDisabledSpeedTiers={onDisabledSpeedTiers}
          onFindDevices={onFindDevices}
          onInvalidModalClose={onInvalidModalClose}
          onLoadAddressSuggestions={onLoadAddressSuggestions}
          onTempLocationTypeChange={onTempLocationTypeChange}
          onTempLocationTypeReset={onTempLocationTypeReset}
          onSearch={onSearch}
          onZipcodeChange={onZipcodeChange}
          onDeviceInfo={onDeviceInfo}
          zipcodeAutofill={zipcodeAutofill}
          zipcodeSpeedTiers={zipcodeSpeedTiers}
        />
        <StatefulHeader
          headline="Devices that work with your Internet speed"
          tagline={
            <>
              <p>Make sure you purchase a device that has a download speed that is faster than your current Internet plan speed.</p>
              <p>Devices are displayed in order from the highest to lowest download speed.</p>
            </>
          }
        />
      </div>
      <div className={container} aria-live="polite">
        <div
          className={showOnMobile ? `${hiddenDiv}` : null}
        >
          <Message
            deviceFilters={deviceFilters}
            deviceResults={deviceResults}
            keyWord={keyWord}
            onShowUnsupportedDevicesChange={onShowUnsupportedDevicesChange}
            yourDevice={yourDevice}
          />
        </div>
        <SortBy
          onSortKeyChange={onSortKeyChange}
          showOnMobile={showOnMobile}
          sortKey={sortKey}
        />
        <Filters
          accountSpeedTier={accountSpeedTier}
          deviceFilters={deviceFilters}
          deviceResults={deviceResults}
          downloadSpeedTiers={downloadSpeedTiers}
          filtersDropdown={filtersDropdown}
          keyWord={keyWord}
          onBrandChange={onBrandChange}
          onBuiltInWifiChange={onBuiltInWifiChange}
          onClearAll={onClearAll}
          onDocsisChannelsChange={onDocsisChannelsChange}
          onDocsisVersionChange={onDocsisVersionChange}
          onDownloadSpeedChange={onDownloadSpeedChange}
          onShowUnsupportedDevicesChange={onShowUnsupportedDevicesChange}
          onVoiceEnabledChange={onVoiceEnabledChange}
          onYourDevice={onYourDevice}
          showOnMobile={showOnMobile}
          yourDevice={yourDevice}
          yourEquipment={yourEquipment}
        />
        <Listings
          deviceFilters={deviceFilters}
          deviceResults={deviceResults}
          devicesForComparison={devicesForComparison}
          isLoadingDevices={isLoadingDevices}
          onDetails={onDetails}
          onSelectToCompare={onSelectToCompare}
          showOnMobile={showOnMobile}
          sortKey={sortKey}
          yourDevice={yourDevice}
          forwardRef={listingsRef}
        />
        <div
          className={showOnMobile ? `${hiddenDiv}` : null}
        >
          <Comparison
            devicesForComparison={devicesForComparison}
            onClearAllDevice={onClearAllDevice}
            onFocusFromComparison={onFocusFromComparison}
            onSelectToCompare={onSelectToCompare}
            onCompare={onCompare}
          />
        </div>
        <div className={showOnMobile ? `${hiddenDiv} ${customerinfoDiv}` : `${customerinfoDiv}`}>
          <p>Devices are displayed in order of the highest download speed, then by highest DOCSIS version with the highest number of router channels. We recommend that you only purchase devices that are fast enough to deliver the internet plan speed you are subscribed to and come with a warranty and/or return policy. If their firmware or software is compromised in any way, it could make your device defective or inoperable. Existing gateways on this page may not fully support faster upload speeds and currently do not support the X-Class Portfolio. For more information visit <a href="https://www.xfinity.com/support/articles/upgraded-areas-leased-equipment-required-for-upload-speeds">www.xfinity.com/support/articles/upgraded-areas-leased-equipment-required-for-upload-speeds</a>.</p>
        </div>
      </div>
      <button
        className={showOnMobile ? `${hiddenDiv} ${filterMobile} ${linkButton}` : `${filterMobile} ${linkButton}`}
        onClick={() => {
          setShowOnMobile(!showOnMobile);
          window.scrollTo(0, 0);
        }}
      >
        <div>Filter</div>
      </button>
      <div className={!showOnMobile ? `${hiddenDiv} ${filterApplyMobile} ${linkButton}` : `${filterApplyMobile} ${linkButton}`}>
        <div>
          <button
            className={button}
            onClick={() => {
              onClearAll();
              setShowOnMobile(!showOnMobile);
            }}
          >
            Cancel
          </button>
          <button
            className={`${button} ${primary}`}
            onClick={() => setShowOnMobile(!showOnMobile)}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

ResultsView.propTypes = {
  accountSpeedTier: PropTypes.number,
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  deviceFilters: PropTypes.object,
  deviceResults: PropTypes.array,
  devicesForComparison: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  downloadSpeedTiers: PropTypes.array,
  filtersDropdown: PropTypes.object,
  isLoadingDevices: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  keyWord: PropTypes.string,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onBrandChange: PropTypes.func.isRequired,
  onBuiltInWifiChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onClearAllDevice: PropTypes.func.isRequired,
  onCompare: PropTypes.func.isRequired,
  onDetails: PropTypes.func.isRequired,
  onDeviceInfo: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onDocsisChannelsChange: PropTypes.func.isRequired,
  onDocsisVersionChange: PropTypes.func.isRequired,
  onDownloadSpeedChange: PropTypes.func.isRequired,
  onFindDevices: PropTypes.func.isRequired,
  onInvalidModalClose: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onTempLocationTypeReset: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onSelectToCompare: PropTypes.func.isRequired,
  onShowUnsupportedDevicesChange: PropTypes.func.isRequired,
  onSortKeyChange: PropTypes.func.isRequired,
  onVoiceEnabledChange: PropTypes.func.isRequired,
  onYourDevice: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  sortKey: PropTypes.string,
  voiceStatus: PropTypes.string,
  yourDevice: PropTypes.object,
  yourEquipment: PropTypes.array,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};
