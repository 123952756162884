exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".section-5e32c{background-color:#fff;padding:2.4rem 0 2.4rem}.wrap-09c21{color:#44484c;font:300 1.6rem/2.4rem XfinityStandard,Helvetica,Arial,sans-serif;letter-spacing:.02rem;background-color:#fff;margin:0 10%}.wrap-09c21 strong{font-weight:500}@media(max-width: 41.5rem){.wrap-09c21{margin:0;padding:0 1.2rem}}.wrap-09c21 .content-e8166{align-items:center;display:flex;margin-bottom:2rem}.wrap-09c21 .content-e8166>div{width:50%}.wrap-09c21 .content-e8166>div p{box-sizing:border-box;padding-right:10%}.wrap-09c21 .content-e8166>div a:hover{text-decoration:none}.wrap-09c21 .content-e8166>div img{height:auto;padding:4rem 0;width:100%}@media(max-width: 41.5rem){.wrap-09c21 .content-e8166{text-align:center}.wrap-09c21 .content-e8166>div{margin:0 10%;width:100%}.wrap-09c21 .content-e8166>div>p{margin-bottom:4rem;padding-right:0;text-align:left}.wrap-09c21 .content-e8166>div:last-child{display:none}}.wrap-09c21 .footNote-ce658{color:#44484c;font:normal 1.4rem/2.1rem XfinityStandard,Helvetica,Arial,sans-serif;letter-spacing:.02rem}.wrap-09c21 .footNote-ce658 strong{font-weight:500}.wrap-09c21 .footNote-ce658 p{margin:0}", ""]);

// Exports
exports.locals = {
	"section": "section-5e32c",
	"wrap": "wrap-09c21",
	"content": "content-e8166",
	"footNote": "footNote-ce658"
};