import isFunction from 'lodash/isFunction';

export const NAVIGATE = 'NAVIGATE';
export const PUSH_HISTORY = 'PUSH_HISTORY';

export const navigate = () => ({
  type: NAVIGATE,
});

const _hash = string => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = (hash << 5) - hash + string.charCodeAt(i);
    hash = hash & hash;
  }
  return hash;
};

export const pushHistory = (action, path, key) => {
  const {pathname, search, hash} = window.location;
  path = path || `${pathname}${search}${hash}`;
  key = key || _hash(isFunction(action) ? action.toString() : JSON.stringify(action));
  return {
    type: PUSH_HISTORY,
    value: {action, path, key},
  };
};
