import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {compose} from 'src/shared/utils/compose';
import {redirect} from 'src/shared/redirect/middleware';
import {createLoggerMiddleware} from 'src/shared/logger/middleware';
import {events} from 'src/shared/events/middleware';
import {auth} from 'src/shared/auth/reducers';
import {account} from 'src/shared/account/reducers';
import {config} from 'src/shared/config/reducers';
import {currentView} from 'src/shared/currentView/reducers';
import {resourceStatus} from 'src/shared/resourceStatus/reducers';
import {params} from 'src/shared/params/reducers';
import {user} from 'src/shared/user/reducers';
import {history} from 'src/shared/history/middleware';
import {createDataLayerMiddleware} from 'src/shared/dataLayer/middleware';
import {
  addressSuggestions,
  addressSpeedTiers,
  addressValid,
  app,
  comparedDevices,
  deviceAutofill,
  deviceDetails,
  deviceResults,
  elocAddress,
  filtersDropdown,
  MDIUserAccountDevices,
  MDIUserAccountProfile,
  MDIUserAccountSpeedtier,
  MDIUserSession,
  session,
  zipcodeAutofill,
  zipcodeSpeedTiers,
} from 'src/pages/deviceInfo/store/reducers/deviceInfo';
import {screenName} from 'src/pages/deviceInfo/store/selectors/screenName';
import {createChannelTrackingMiddleware} from 'src/shared/channelTracking/middleware';

const reducer = combineReducers({
  account,
  addressSuggestions,
  addressSpeedTiers,
  addressValid,
  app,
  auth,
  config,
  comparedDevices,
  currentView,
  deviceAutofill,
  deviceDetails,
  deviceResults,
  elocAddress,
  filtersDropdown,
  MDIUserAccountDevices,
  MDIUserAccountProfile,
  MDIUserAccountSpeedtier,
  MDIUserSession,
  params,
  resourceStatus,
  session,
  user,
  zipcodeAutofill,
  zipcodeSpeedTiers,
});

export const store = createStore(
  reducer,
  compose(
    applyMiddleware(
      thunk,
      history,
      redirect,
      createLoggerMiddleware({
        component: 'XHS-PAGE-DEVICE-INFO',
        page: 'devices',
        types: [],
      }),
      createDataLayerMiddleware(screenName),
      createChannelTrackingMiddleware(),
      events
    )
  )
);
