exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".modalBtn-c4bf8{color:#0272b6;cursor:pointer}.modalBtn-c4bf8:focus{border:.1rem dashed #0272b6;border-radius:.3rem;outline:none}.modalCover-28ff8{background-color:#2f3845;height:100%;left:0;position:fixed;top:0;width:100%;z-index:4}.modalCover-28ff8 .modal-e18b2{background-color:#fff;border-radius:.3rem;box-shadow:0 0 1rem .3rem #44484c;left:50%;overflow:visible;padding:3rem 4rem;position:absolute;top:50%;transform:translate(-50%, -50%);width:63%}.modalCover-28ff8 .modal-e18b2 .modalClose-3c7e0{cursor:pointer;float:right;height:3rem;width:3rem}.modalCover-28ff8 .modal-e18b2 .modalClose-3c7e0 .closeIcon-38a81{color:#c7cdd2}.modalCover-28ff8 .modal-e18b2 .modalClose-3c7e0:focus{border:.1rem dashed #0272b6;border-radius:0 .3rem .3rem 0;outline:none}.modalCover-28ff8 .modal-e18b2 .modalBody-b5048 p{font-size:1.6rem}@media(max-width: 41.5rem){.modalCover-28ff8 .modal-e18b2{padding:2rem;width:85%}}", ""]);

// Exports
exports.locals = {
	"modalBtn": "modalBtn-c4bf8",
	"modalCover": "modalCover-28ff8",
	"modal": "modal-e18b2",
	"modalClose": "modalClose-3c7e0",
	"closeIcon": "closeIcon-38a81",
	"modalBody": "modalBody-b5048"
};