import React from 'react';
import ReactDOM from 'react-dom';
import 'src/shared/a11y/reactAxe';
import {Provider} from 'react-redux';
import {store} from './store';
import {startFlow} from './store/actions/startFlow';
import {App} from './components/App';
import set from 'lodash/set';
import 'src/styles/base.scss';

store.dispatch(startFlow());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
);

set(window, 'XHS.store', store);
