import React from 'react';
import PropTypes from 'prop-types';
import {button, primaryLight} from 'src/styles/modules/button.module.scss';
import {StatefulHeader} from 'src/shared/components/StatefulHeader';
import {FindDevice} from '../FindDevice';
import {Promo} from '../Promo';
import {DeviceSearchHeader} from '../DeviceSearchHeader';
import {
  buttonMargin,
  findDeviceWrapper,
  businessDevice,
  linkMargin,
  linkMarginLeft,
} from './styles.module.scss';
import RightArrowIcon from 'src/svgs/mdi/rightArrow.svg';
import {light} from 'src/styles/modules/links.module.scss';

export const Unauthenticated = ({
  addressSpeedTiers,
  addressSuggestions,
  disabledSpeedTiers,
  isLoadingSpeedTiers,
  location,
  locationErrorMessage,
  onAddressChange,
  onClearAddressSuggestions,
  onDeviceInfo,
  onDisabledSpeedTiers,
  onFindDevices,
  onInvalidModalClose,
  onLoadAddressSuggestions,
  onTempLocationTypeChange,
  onSearch,
  onZipcodeChange,
  signIn,
  zipcodeAutofill,
  zipcodeSpeedTiers,
}) => (
  <>
    <DeviceSearchHeader
      onFindDevices={onFindDevices}
      onSearch={onSearch}
      onDeviceInfo={onDeviceInfo}
    />
    <StatefulHeader
      headline="Welcome!"
      tagline={
        <p>Interested in bringing your own modem? Before purchasing,
        or to check your current modem, use this site to easily determine
        if your device is approved for Xfinity Internet and/or Voice service
        and will support your Internet speed. <br />To begin, sign in or
        enter your {location.locationType} below. For instructions on how to find the right device,
        <a
          className={`${linkMargin} ${light}`}
          href="https://www.xfinity.com/support/articles/list-of-approved-cable-modems"
          rel="noopener noreferrer"
          target="_blank"
        >
          click here
        </a>
        </p>
      }
    >
      <a
        href={signIn}
        className={`${button} ${primaryLight} ${buttonMargin}`}
      >
        Sign In
      </a>
      <p>Forgot your
        <a
          className={`${linkMargin} ${light}`}
          href="https://idm.xfinity.com/myaccount/reset?execution=e4s3"
          rel="noopener noreferrer"
          target="_blank"
        >
          username
        </a>
        or
        <a
          className={`${linkMarginLeft} ${light}`}
          href="https://login.comcast.net/forgotpwd/reset?execution=e1s1"
          rel="noopener noreferrer"
          target="_blank"
        >
          password
        </a>
        ?
      </p>
      <p>Learn how to activate your new modem,
        <a
          className={`${linkMargin} ${light}`}
          href="https://www.xfinity.com/support/articles/activate-internet-gateway-modem"
          rel="noopener noreferrer"
          target="_blank"
        >
          click here
        </a>
      </p>
    </StatefulHeader>
    <div className={findDeviceWrapper}>
      <FindDevice
        addressSpeedTiers={addressSpeedTiers}
        addressSuggestions={addressSuggestions}
        disabledSpeedTiers={disabledSpeedTiers}
        isLoadingSpeedTiers={isLoadingSpeedTiers}
        location={location}
        locationErrorMessage={locationErrorMessage}
        onAddressChange={onAddressChange}
        onClearAddressSuggestions={onClearAddressSuggestions}
        onDisabledSpeedTiers={onDisabledSpeedTiers}
        onFindDevices={onFindDevices}
        onInvalidModalClose={onInvalidModalClose}
        onLoadAddressSuggestions={onLoadAddressSuggestions}
        onTempLocationTypeChange={onTempLocationTypeChange}
        onSearch={onSearch}
        onZipcodeChange={onZipcodeChange}
        zipcodeAutofill={zipcodeAutofill}
        zipcodeSpeedTiers={zipcodeSpeedTiers}
      />
      <div className={businessDevice}>
        <h3>Comcast Business customer?</h3>
        <a href="https://business.comcast.com/help-and-support/internet/comcast-business-cable-modem-device-compatibility" rel="noopener noreferrer" target="_blank">
          Find business Devices
          <RightArrowIcon className={linkMarginLeft} />
        </a>
      </div>
    </div>
    <Promo />
  </>
);

Unauthenticated.propTypes = {
  addressSpeedTiers: PropTypes.array,
  addressSuggestions: PropTypes.array,
  disabledSpeedTiers: PropTypes.bool,
  isLoadingSpeedTiers: PropTypes.bool,
  location: PropTypes.object,
  locationErrorMessage: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onClearAddressSuggestions: PropTypes.func.isRequired,
  onDeviceInfo: PropTypes.func.isRequired,
  onDisabledSpeedTiers: PropTypes.func.isRequired,
  onFindDevices: PropTypes.func.isRequired,
  onInvalidModalClose: PropTypes.func.isRequired,
  onLoadAddressSuggestions: PropTypes.func.isRequired,
  onTempLocationTypeChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  signIn: PropTypes.string.isRequired,
  zipcodeAutofill: PropTypes.array,
  zipcodeSpeedTiers: PropTypes.array,
};
