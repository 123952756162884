import {changeView} from 'src/shared/currentView/actions';
import {
  LOADING_VIEW,
  RESULTS_VIEW,
  UNAUTHENTICATED_VIEW,
  EPON_ADDRESS,
  FDX_DISCLAIMER,
} from '../../constants/views';
import {
  waitForNext,
  waitUntil,
} from 'src/shared/events';
import {createFetchResourceAction} from 'src/shared/utils/resource';
import {
  deviceFilters,
  keyWord,
  accessToken,
  addressValid,
  location,
  locationType,
  pageFragment,
  downloadSpeedTiers,
  tempDownloadSpeedTiers,
  isSpeedtierFdxActive,
} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {
  mdiAddressSearch,
  mdiAddressSpeedTiers,
  mdiAddressValid,
  mdiDeviceSearch,
  mdiZipcodeAutofill,
  mdiZipcodeSpeedTiers,
} from 'src/shared/config/urls';
import {
  loadFiltersDropdown,
  LOAD_FILTERS_DROPDOWN_COMPLETE,
  setKeyWord,
} from 'src/pages/deviceInfo/store/actions/deviceSearch';
import {address} from 'src/pages/deviceInfo/constants/locationTypes';
import {clearAllDevicesForComparison} from 'src/pages/deviceInfo/store/actions/compareDevices';
import {setClearAll} from 'src/pages/deviceInfo/store/actions/setClearAll';
import {setDisabledSpeedTiers} from 'src/pages/deviceInfo/store/actions/setDisabledSpeedTiers';
import {setPageFragment} from 'src/pages/deviceInfo/store/actions/setPageFragment';
import {
  setDefaultDownloadSpeed,
  setDownloadSpeed,
} from 'src/pages/deviceInfo/store/actions/setDownloadSpeed';
import {setDownloadSpeedTiers} from 'src/pages/deviceInfo/store/actions/setDownloadSpeedTiers';
import {pushHistory} from 'src/shared/history/actions';
import {fragment} from 'src/pages/scheduleCallback/utils';
import md5 from 'md5';
import {setAddressError} from 'src/pages/deviceInfo/store/actions/setAddressError';
import {
  readMDIUserAccountSpeedtier,
  READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE,
} from './readMDIUserAccount';
import {isFullyAuthenticated} from 'src/shared/auth/selectors';
export const LOAD_DEVICES_RESULTS_START = 'LOAD_DEVICES_RESULTS_START';
export const LOAD_DEVICES_RESULTS_SUCCESS = 'LOAD_DEVICES_RESULTS_SUCCESS';
export const LOAD_DEVICES_RESULTS_ERROR = 'LOAD_DEVICES_RESULTS_ERROR';
export const LOAD_DEVICES_RESULTS_COMPLETE = 'LOAD_DEVICES_RESULTS_COMPLETE';

export const LOAD_ADDRESS_SUGGESTIONS_START = 'LOAD_ADDRESS_SUGGESTIONS_START';
export const LOAD_ADDRESS_SUGGESTIONS_SUCCESS = 'LOAD_ADDRESS_SUGGESTIONS_SUCCESS';
export const LOAD_ADDRESS_SUGGESTIONS_ERROR = 'LOAD_ADDRESS_SUGGESTIONS_ERROR';
export const LOAD_ADDRESS_SUGGESTIONS_COMPLETE = 'LOAD_ADDRESS_SUGGESTIONS_COMPLETE';

export const LOAD_ZIPCODE_AUTOFILL_START = 'LOAD_ZIPCODE_AUTOFILL_START';
export const LOAD_ZIPCODE_AUTOFILL_SUCCESS = 'LOAD_ZIPCODE_AUTOFILL_SUCCESS';
export const LOAD_ZIPCODE_AUTOFILL_ERROR = 'LOAD_ZIPCODE_AUTOFILL_ERROR';
export const LOAD_ZIPCODE_AUTOFILL_COMPLETE = 'LOAD_ZIPCODE_AUTOFILL_COMPLETE';

export const LOAD_ADDRESS_VALID_START = 'LOAD_ADDRESS_VALID_START';
export const LOAD_ADDRESS_VALID_SUCCESS = 'LOAD_ADDRESS_VALID_SUCCESS';
export const LOAD_ADDRESS_VALID_ERROR = 'LOAD_ADDRESS_VALID_ERROR';
export const LOAD_ADDRESS_VALID_COMPLETE = 'LOAD_ADDRESS_VALID_COMPLETE';

export const LOAD_ADDRESS_SPEED_TIERS_START = 'LOAD_ADDRESS_SPEED_TIERS_START';
export const LOAD_ADDRESS_SPEED_TIERS_SUCCESS = 'LOAD_ADDRESS_SPEED_TIERS_SUCCESS';
export const LOAD_ADDRESS_SPEED_TIERS_ERROR = 'LOAD_ADDRESS_SPEED_TIERS_ERROR';
export const LOAD_ADDRESS_SPEED_TIERS_COMPLETE = 'LOAD_ADDRESS_SPEED_TIERS_COMPLETE';

export const LOAD_ZIPCODE_SPEED_TIERS_START = 'LOAD_ZIPCODE_SPEED_TIERS_START';
export const LOAD_ZIPCODE_SPEED_TIERS_SUCCESS = 'LOAD_ZIPCODE_SPEED_TIERS_SUCCESS';
export const LOAD_ZIPCODE_SPEED_TIERS_ERROR = 'LOAD_ZIPCODE_SPEED_TIERS_ERROR';
export const LOAD_ZIPCODE_SPEED_TIERS_COMPLETE = 'LOAD_ZIPCODE_SPEED_TIERS_COMPLETE';

export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_INVALID_MODAL_CLOSE = 'SET_INVALID_MODAL_CLOSE';
export const SET_IS_EPON_ADDRESS = 'SET_IS_EPON_ADDRESS';
export const SET_LOCATION_TYPE = 'SET_LOCATION_TYPE';
export const SET_ZIPCODE = 'SET_ZIPCODE';
export const SET_TEMP_LOCATION = 'SET_TEMP_LOCATION';
export const SET_TEMP_LOCATION_TYPE = 'SET_TEMP_LOCATION_TYPE';
export const CLEAR_ADDRESS_SUGGESTIONS = 'CLEAR_ADDRESS_SUGGESTIONS';

export const loadDeviceResults = () => createFetchResourceAction({
  start: LOAD_DEVICES_RESULTS_START,
  success: LOAD_DEVICES_RESULTS_SUCCESS,
  error: LOAD_DEVICES_RESULTS_ERROR,
  complete: LOAD_DEVICES_RESULTS_COMPLETE,
  url: mdiDeviceSearch,
  fetchOptions: state => ({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      searchKeywords: keyWord(state),
      dsSpeed: deviceFilters(state).downloadSpeed,
      voice: deviceFilters(state).voiceEnabled,
      wifi: deviceFilters(state).builtInWifi,
      vendorNames: deviceFilters(state).brand,
      docsisChannels: deviceFilters(state).docsisChannels,
      docsisVersions: deviceFilters(state).docsisVersion,
      customerType: 'Residential',
    }),
  }),
  token: accessToken,
});

export const loadAddressSuggestions = address => createFetchResourceAction({
  start: LOAD_ADDRESS_SUGGESTIONS_START,
  success: LOAD_ADDRESS_SUGGESTIONS_SUCCESS,
  error: LOAD_ADDRESS_SUGGESTIONS_ERROR,
  complete: LOAD_ADDRESS_SUGGESTIONS_COMPLETE,
  url: state => mdiAddressSearch(state, address),
  token: accessToken,
  fetchOptions: {
    suppressLogging: (response, elapsedTime) => response.ok && elapsedTime < 3000,
  },
});

export const clearAddressSuggestions = () => ({
  type: CLEAR_ADDRESS_SUGGESTIONS,
});

export const loadZipcodeAutofill = () => createFetchResourceAction({
  start: LOAD_ZIPCODE_AUTOFILL_START,
  success: LOAD_ZIPCODE_AUTOFILL_SUCCESS,
  error: LOAD_ZIPCODE_AUTOFILL_ERROR,
  complete: LOAD_ZIPCODE_AUTOFILL_COMPLETE,
  url: mdiZipcodeAutofill,
  token: accessToken,
});

export const loadAddressValid = address => createFetchResourceAction({
  start: LOAD_ADDRESS_VALID_START,
  success: LOAD_ADDRESS_VALID_SUCCESS,
  error: LOAD_ADDRESS_VALID_ERROR,
  complete: LOAD_ADDRESS_VALID_COMPLETE,
  url: state => mdiAddressValid(state, address),
  token: accessToken,
});

export const loadAddressSpeedTiers = elocId => createFetchResourceAction({
  start: LOAD_ADDRESS_SPEED_TIERS_START,
  success: LOAD_ADDRESS_SPEED_TIERS_SUCCESS,
  error: LOAD_ADDRESS_SPEED_TIERS_ERROR,
  complete: LOAD_ADDRESS_SPEED_TIERS_COMPLETE,
  url: state => mdiAddressSpeedTiers(state, elocId),
  token: accessToken,
});

export const loadZipcodeSpeedTiers = zip5 => createFetchResourceAction({
  start: LOAD_ZIPCODE_SPEED_TIERS_START,
  success: LOAD_ZIPCODE_SPEED_TIERS_SUCCESS,
  error: LOAD_ZIPCODE_SPEED_TIERS_ERROR,
  complete: LOAD_ZIPCODE_SPEED_TIERS_COMPLETE,
  url: state => mdiZipcodeSpeedTiers(state, zip5),
  token: accessToken,
});

export const setLocationType = locationType => ({
  type: SET_LOCATION_TYPE,
  value: {locationType},
});

export const setAddress = address => ({
  type: SET_ADDRESS,
  value: {address},
});

export const setZipcode = zipcode => ({
  type: SET_ZIPCODE,
  value: {zipcode},
});

export const setTempLocation = tempLocation => ({
  type: SET_TEMP_LOCATION,
  value: {tempLocation},
});

export const setTempLocationType = tempLocationType => ({
  type: SET_TEMP_LOCATION_TYPE,
  value: {tempLocationType},
});

export const setInvalidModalClose = invalidModalClose => ({
  type: SET_INVALID_MODAL_CLOSE,
  value: {invalidModalClose},
});

export const setIsEPONAddress = isEPONAddress => ({
  type: SET_IS_EPON_ADDRESS,
  value: {isEPONAddress},
});

export const resetTempLocationType = () => (dispatch, getState) => {
  dispatch(setTempLocationType(location(getState()).locationType));
};

export const findDeviceFragment = (page, speed, location) => async (dispatch, getState) => {
  const previousPage = pageFragment(getState());
  if (previousPage !== 'details' && previousPage !== 'comparison' && previousPage !== 'landing') {
    dispatch(setClearAll());
    dispatch(setDefaultDownloadSpeed(speed));
    dispatch(setDownloadSpeed(speed));
  }
  if (locationType(getState()) === address) {
    dispatch(setAddress(location));
  } else {
    dispatch(setZipcode(location));
  }
  dispatch(setPageFragment(page));
  dispatch(setKeyWord(null));
  dispatch(loadDeviceResults());
  dispatch(setDownloadSpeedTiers(tempDownloadSpeedTiers(getState())));
  await waitForNext(LOAD_DEVICES_RESULTS_COMPLETE);
  if (!downloadSpeedTiers(getState()).includes(speed)) {
    dispatch(setPageFragment('unauth'));
    return dispatch(changeView(UNAUTHENTICATED_VIEW));
  }
  dispatch(pushHistory(
    findDeviceFragment(page, speed, location),
    fragment(page),
    md5(page, speed, location)
  ));

  return dispatch(changeView(RESULTS_VIEW));
};

export const findDevices = speed => async (dispatch, getState) => {
  dispatch(changeView(LOADING_VIEW));
  if (isFullyAuthenticated(getState())) {
    dispatch(readMDIUserAccountSpeedtier());
    await waitUntil(
      READ_MDI_USER_ACCOUNT_SPEEDTIER_COMPLETE
    );
    if (isSpeedtierFdxActive(getState())) {
      dispatch(setLocationType(location(getState()).tempLocationType));
      dispatch(setDisabledSpeedTiers(true));
      dispatch(setClearAll());
      return dispatch(changeView(FDX_DISCLAIMER));
    }
  }
  dispatch(loadAddressValid(location(getState()).tempLoscation));
  await waitUntil(LOAD_ADDRESS_VALID_COMPLETE);
  if (addressValid(getState()).fdxActive) {
    dispatch(setLocationType(location(getState()).tempLocationType));
    dispatch(setDisabledSpeedTiers(true));
    dispatch(setClearAll());
    return dispatch(changeView(FDX_DISCLAIMER));
  }

  dispatch(clearAllDevicesForComparison());
  dispatch(loadFiltersDropdown());
  dispatch(setLocationType(location(getState()).tempLocationType));
  dispatch(setDisabledSpeedTiers(true));
  dispatch(setClearAll());
  dispatch(setDefaultDownloadSpeed(speed));
  dispatch(setDownloadSpeed(speed));
  await waitUntil(LOAD_FILTERS_DROPDOWN_COMPLETE);
  dispatch(findDeviceFragment('results', speed, location(getState()).tempLocation));
};

export const getAddressSpeed = address => async (dispatch, getState) => {
  dispatch(setIsEPONAddress(false));
  dispatch(loadAddressValid(address));
  await waitForNext(LOAD_ADDRESS_VALID_COMPLETE);

  if (!addressValid(getState()).validAddress) {
    return dispatch(setAddressError());
  }
  dispatch(setTempLocation(address));
  dispatch(loadAddressSpeedTiers(addressValid(getState()).elocId));

  if (addressValid(getState()).fdxActive) {
    return dispatch(changeView(FDX_DISCLAIMER));
  }

  if (addressValid(getState()).dropType === 'EPON') {
    dispatch(setIsEPONAddress(true));
    if (addressValid(getState()).fullStreetAddress) {
      dispatch(setAddress(addressValid(getState()).fullStreetAddress));
    }
    return dispatch(changeView(EPON_ADDRESS));
  }
};
