import {
  accountSpeedTier,
  pageFragment,
  defaultDownloadSpeed,
} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {setDownloadSpeed} from 'src/pages/deviceInfo/store/actions/setDownloadSpeed';

export const CLEAR_ALL = 'CLEAR_ALL';

export const setClearAll = () => ({
  type: CLEAR_ALL,
});

export const setClearAllFilter = () => (dispatch, getState) => {
  const page = pageFragment(getState());
  if (page === 'auth') {
    dispatch(setDownloadSpeed(accountSpeedTier(getState())));
  } else if (page === 'search') {
    dispatch(setDownloadSpeed(null));
  } else {
    dispatch(setDownloadSpeed(defaultDownloadSpeed(getState())));
  }
  dispatch(setClearAll());
};
