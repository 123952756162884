import {waitForNext} from 'src/shared/events';
import {wait} from 'src/shared/api';
import {
  getMDISession,
  GET_MDI_SESSION_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/getMDISession';
import {expiresIn} from 'src/pages/deviceInfo/store/selectors/deviceInfo';
import {
  getMDIUserSession,
  GET_MDI_USER_SESSION_COMPLETE,
} from 'src/pages/deviceInfo/store/actions/readMDIUserAccount';
import {isFullyAuthenticated} from 'src/shared/auth/selectors';

export const maintainMDISession = (maxRefreshes = 100) => async (dispatch, getState) => {
  for (let attempts = 0; attempts < maxRefreshes; attempts += 1) {
    if (isFullyAuthenticated(getState())) {
      dispatch(getMDIUserSession());
      await waitForNext(GET_MDI_USER_SESSION_COMPLETE);
    } else {
      dispatch(getMDISession());
      await waitForNext(GET_MDI_SESSION_COMPLETE);
    }
    await wait(expiresIn(getState()) * 1000 - 30 * 1000);
  }
};
