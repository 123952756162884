import React from 'react';
import {LoadingCircle} from 'src/shared/components/LoadingCircle';
import {loading} from './styles.module.scss';

export const LoadingView = () => {
  return (
    <div className={loading}>
      <LoadingCircle />
    </div>
  );
};
