exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container-5c23b{color:#44484c;font:normal 1.4rem/2.1rem XfinityStandard,Helvetica,Arial,sans-serif;letter-spacing:.02rem;background-color:#f2f4f5;margin:0 auto;max-width:102.4rem;padding:0 1.2rem}.container-5c23b strong{font-weight:500}.customerinfoDiv-20654{color:#191919;font:normal 1.2rem/1.8rem XfinityStandard,Helvetica,Arial,sans-serif;letter-spacing:.02rem;display:block;margin-top:5rem}.filterApplyMobile-7a5c0,.filterMobile-24bab{bottom:0;box-shadow:.4rem 0 .7rem #868f96;display:none;position:fixed;width:100%;z-index:3}@media(max-width: 41.5rem){.filterApplyMobile-7a5c0,.filterMobile-24bab{display:block}}.filterMobile-24bab{background-color:#fff;padding:1.5rem 1rem}.filterMobile-24bab>div{margin:auto}.filterApplyMobile-7a5c0>div{background-color:#fff;display:flex;justify-content:space-between;padding:1.5rem 1rem}@media(max-width: 41.5rem){.hiddenDiv-c921a{display:none !important}}", ""]);

// Exports
exports.locals = {
	"container": "container-5c23b",
	"customerinfoDiv": "customerinfoDiv-20654",
	"filterApplyMobile": "filterApplyMobile-7a5c0",
	"filterMobile": "filterMobile-24bab",
	"hiddenDiv": "hiddenDiv-c921a"
};