import {createFetchResourceAction} from 'src/shared/utils/resource';
import {mdiSession} from 'src/shared/config/urls.js';

export const GET_MDI_SESSION_START = 'GET_MDI_SESSION_START';
export const GET_MDI_SESSION_COMPLETE = 'GET_MDI_SESSION_COMPLETE';
export const GET_MDI_SESSION_SUCCESS = 'GET_MDI_SESSION_SUCCESS';
export const GET_MDI_SESSION_ERROR = 'GET_MDI_SESSION_ERROR';

export const getMDISession = () => createFetchResourceAction({
  start: GET_MDI_SESSION_START,
  success: GET_MDI_SESSION_SUCCESS,
  error: GET_MDI_SESSION_ERROR,
  complete: GET_MDI_SESSION_COMPLETE,
  url: mdiSession,
  fetchOptions: {
    method: 'POST',
  },
});
