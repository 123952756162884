import React from 'react';
import PropTypes from 'prop-types';
import {LoadingDots} from 'src/shared/components/LoadingDots';
import {loadingCircle} from './style.module.scss';

export const LoadingCircle = ({
  className,
}) => (
  <div className={[loadingCircle, className].filter(Boolean).join(' ')}>
    <LoadingDots light />
  </div>
);

LoadingCircle.propTypes = {
  className: PropTypes.string,
};
